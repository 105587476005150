import { BasePaginationRespone } from "../../base/BasePaginationRespone";
import { BaseSelectRequest } from "../../base/BaseSelectRequest";
import { BaseShowDetail } from "../../base/BaseShowDetail";
import { IActionTypeBase } from "../../base/IBaseActionTypes";
import { ArticleCategoryModel } from "../../models/articles-category/ArticleCategoryModel";

export enum eArticleCategoryActionTypeIds {
    GET_ALL_REQUEST = "ArticleCategory_GET_ALL_REQUEST",
    GET_ALL_SUCCESS = "ArticleCategory_GET_ALL_SUCCESS",
    GET_ALL_FAILURE = "ArticleCategory_GET_ALL_FAILURE",

    GET_PAGINATION_REQUEST = "ArticleCategory_GET_PAGINATION_REQUEST",
    GET_PAGINATION_SUCCESS = "ArticleCategory_GET_PAGINATION_SUCCESS",
    GET_PAGINATION_FAILURE = "ArticleCategory_GET_PAGINATION_FAILURE",

    SAVE_REQUEST = "ArticleCategory_SAVE_REQUEST",
    SAVE_SUCCESS = "ArticleCategory_SAVE_SUCCESS",
    SAVE_FAILURE = "ArticleCategory_SAVE_FAILURE",

    DELETE_REQUEST = "ArticleCategory_DELETE_REQUEST",
    DELETE_SUCCESS = "ArticleCategory_DELETE_SUCCESS",
    DELETE_FAILURE = "ArticleCategory_DELETE_FAILURE",

    NEED_RELOAD = "ArticleCategory_NEED_RELOAD",

    CHANGE_SELECTED_IDS = "ArticleCategory_CHANGE_SELECTED_IDS",
    SHOW_DETAIL = "ArticleCategory_SHOW_DETAIL",
    SHOW_CONFIRM = "ArticleCategory_SHOW_CONFIRM",
}

export interface ArticleCategoryAction_GETALL_Request extends IActionTypeBase<eArticleCategoryActionTypeIds.GET_ALL_REQUEST, undefined> { }
export interface ArticleCategoryAction_GETALL_Success extends IActionTypeBase<eArticleCategoryActionTypeIds.GET_ALL_SUCCESS, ArticleCategoryModel[]> { }
export interface ArticleCategoryAction_GETALL_Failure extends IActionTypeBase<eArticleCategoryActionTypeIds.GET_ALL_FAILURE, Error | string> { }

export interface ArticleCategoryAction_GETPAGINATION_Request extends IActionTypeBase<eArticleCategoryActionTypeIds.GET_PAGINATION_REQUEST, BaseSelectRequest> { }
export interface ArticleCategoryAction_GETPAGINATION_Success extends IActionTypeBase<eArticleCategoryActionTypeIds.GET_PAGINATION_SUCCESS, BasePaginationRespone<ArticleCategoryModel>> { }
export interface ArticleCategoryAction_GETPAGINATION_Failure extends IActionTypeBase<eArticleCategoryActionTypeIds.GET_PAGINATION_FAILURE, Error | string> { }

export interface ArticleCategoryAction_SAVE_Request extends IActionTypeBase<eArticleCategoryActionTypeIds.SAVE_REQUEST, ArticleCategoryModel> { }
export interface ArticleCategoryAction_SAVE_Success extends IActionTypeBase<eArticleCategoryActionTypeIds.SAVE_SUCCESS, string> { }
export interface ArticleCategoryAction_SAVE_Failure extends IActionTypeBase<eArticleCategoryActionTypeIds.SAVE_FAILURE, Error | string> { }

export interface ArticleCategoryAction_DELETE_Request extends IActionTypeBase<eArticleCategoryActionTypeIds.DELETE_REQUEST, number[]> { }
export interface ArticleCategoryAction_DELETE_Success extends IActionTypeBase<eArticleCategoryActionTypeIds.DELETE_SUCCESS, string> { }
export interface ArticleCategoryAction_DELETE_Failure extends IActionTypeBase<eArticleCategoryActionTypeIds.DELETE_FAILURE, Error | string> { }

export interface ArticleCategoryAction_RELOAD extends IActionTypeBase<eArticleCategoryActionTypeIds.NEED_RELOAD, undefined> { }

export interface ArticleCategoryAction_SHOW_DETAIL extends IActionTypeBase<eArticleCategoryActionTypeIds.SHOW_DETAIL, BaseShowDetail<ArticleCategoryModel>> { }
export interface ArticleCategoryAction_CHANGE_SELECTED_IDS extends IActionTypeBase<eArticleCategoryActionTypeIds.CHANGE_SELECTED_IDS, number[]> { }
export interface ArticleCategoryAction_SHOW_CONFIRM extends IActionTypeBase<eArticleCategoryActionTypeIds.SHOW_CONFIRM, boolean> { }

export type ArticleCategoryActionTypes = ArticleCategoryAction_GETALL_Request | ArticleCategoryAction_GETALL_Success | ArticleCategoryAction_GETALL_Failure |
    ArticleCategoryAction_GETPAGINATION_Request | ArticleCategoryAction_GETPAGINATION_Success | ArticleCategoryAction_GETPAGINATION_Failure |
    ArticleCategoryAction_SAVE_Request | ArticleCategoryAction_SAVE_Success | ArticleCategoryAction_SAVE_Failure |
    ArticleCategoryAction_DELETE_Request | ArticleCategoryAction_DELETE_Success | ArticleCategoryAction_DELETE_Failure | ArticleCategoryAction_RELOAD |
    ArticleCategoryAction_SHOW_DETAIL | ArticleCategoryAction_CHANGE_SELECTED_IDS | ArticleCategoryAction_SHOW_CONFIRM 