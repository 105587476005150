import { all, call, put, fork, takeEvery } from "redux-saga/effects";
import { actions } from "../rootActions";
import { BaseResponse } from "../../base/BaseResponse";
import { rootApi } from "../../api/rootApi";
import { RoleScopesAction_GETALL_Request, eRoleScopesActionTypeIds } from "./IRoleScopesActionsTypes";
import { RoleScopeModel } from "../../models/roles/RoleScopeModel";

function* onLoadAllRoleScopes(action: RoleScopesAction_GETALL_Request) {
    try {
        const res: BaseResponse = yield call([rootApi.role, rootApi.role.SelectAllRoleScopesAsync]);
        if (res.isSuccess && res.result) {
            const listRoleScopes = res.result as RoleScopeModel[];
            yield put(actions.roleScopes.getAllSuccess(listRoleScopes));
        }
        else {
            yield put(actions.roleScopes.getAllFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.roleScopes.getAllFailure(error || 'Có lỗi'));
    }
}


function* watchOnLoadAllRoleScopes() {
    yield takeEvery(eRoleScopesActionTypeIds.GET_ALL_REQUEST, onLoadAllRoleScopes);
}

function* roleScopesSaga() {
    yield all([fork(watchOnLoadAllRoleScopes)]);
}

export default roleScopesSaga;