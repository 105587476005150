import React from 'react';
import { createUUID } from '../../../utils/createUUID';

type IFormProps = {
	children?: any;
	onSubmit: (event: any) => void;
};

export const BaseForm = ({ children, onSubmit }: IFormProps) => {
	return <form onSubmit={onSubmit}>{children}</form>;
};

type IFormGroupProps = {
	name?: string;
	children?: any;
	label?: string;
	helpBlock?: string;
	className?: string;
	labelSmall?: boolean;
	hasValid?: boolean;
	error?: string;
};

export const FormGroup = (props: IFormGroupProps) => {
	const { label, helpBlock, labelSmall, hasValid, error, children, className } = props;
	const showError = error ? true : false;
	const ui_id = createUUID();
	return (
		<div className='form-group'>
			{label && (
				<div className='form-label-group'>
					<label className={`form-label ${labelSmall ? 'form-label-small' : ''}`} htmlFor={ui_id}>
						{label} {hasValid && <em className='text-danger'>(*)</em>}
					</label>
				</div>
			)}
			<div className={`form-control-wrap ${className}`}>
				{children}
				{showError && <span className='form-note invalid'>{error}</span>}
				{helpBlock && <span className='form-note text-muted' dangerouslySetInnerHTML={{ __html: helpBlock }} />}
			</div>
		</div>
	);
};
