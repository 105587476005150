import * as Yup from 'yup';

export type UserGroupModel = {
    id: number;
    name: string;
    description: string;
    sortOrder: number;
    roleIds: string;
    isActive: boolean;
}

export const df_UserGroupModel: UserGroupModel = {
    id: 0,
    name: '',
    description: '',
    isActive: false,
    roleIds: '',
    sortOrder: 0
}

export const valid_UserGroupModel = () => Yup.object().shape({
    id: Yup.number(),
    name: Yup.string().required('Vui lòng nhập tên tác nhân'),
    description: Yup.string(),
    sortOrder: Yup.number(),
    roleIds: Yup.string().required('Vui lòng chọn quyền hạn'),
    isActive: Yup.boolean(),
});