import React from 'react';
import { Outlet } from 'react-router';
import logo_cms from '../../assets/images/promo-a.png'
import logo_cms_2x from '../../assets/images/promo-a2x.png'

const PublicLayout = () => {
	return (
		<>
			<div className='nk-body  npc-default pg-auth'>
				<div className='nk-app-root'>
					<div className='nk-main'>
						<div className='nk-wrap nk-wrap-nosidebar bg-lighter'>
							<div className='nk-content'>
								<div className='nk-split nk-split-page nk-split-lg'>
									<div className='nk-split-content nk-block-area nk-block-area-column nk-auth-container'>
										<div className='nk-block nk-block-middle nk-auth-body bg-white'>
											<Outlet />
										</div>
										<div className='nk-block nk-auth-footer'>
											<div className='container-fluid'>
												<div className='nk-footer-wrap'>
													<div className='nk-footer-copyright'>© 2023. All Rights Reserved.</div>
												</div>
											</div>
										</div>
									</div>
									<div className='nk-split-content nk-split-stretch d-flex toggle-break-lg toggle-slide toggle-slide-right toggle-screen-lg'>
										<div className='slider-wrap w-100 mx-3 p-3 p-sm-5 m-auto '>
											<div className='slider-init slick-initialized slick-slider slick-dotted'>
												<div className='slider-item slick-slide slick-current slick-active'>
													<div className='nk-feature nk-feature-center'>
														<div className='nk-feature-img'>
															<img
																className='round'
																src={logo_cms}
																srcSet={`${logo_cms_2x} 2x`}
																alt='Quang Trung software - Cung cấp dịch vụ thiết kế và vận hành website trọn gói.'
															/>
														</div>
														<div className='nk-feature-content py-4 p-sm-5'>
															<h3><b>Hệ thống quản trị nội dung website</b></h3>
															<p>Quản lý nội dung blog tư vấn</p>
															<p>Quản lý nội dung sản phẩm</p>
															<p>Quản lý đơn hàng</p>
															<p>Quản lý nội dung landingpage</p>
															<p>Quản lý Từ khóa seo web</p>
															<p>Báo cáo tổng hợp</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default PublicLayout;
