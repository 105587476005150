import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
	BaseForm,
	ButtonCancel,
	ButtonSave,
	FormGroup,
	ModalBody,
	ModalDraggable,
	ModalFooter,
	ModalHeader,
	SwitchBox,
	TextAreaBox,
	TextBox,
} from '../../../../components/base';
import { CheckedBoxListRole } from '../../../../components/shared/system';
import { UserGroupModel, df_UserGroupModel, valid_UserGroupModel } from '../../../../context/models/users-group/UserGroupModel';

type IUsersGroupDetailModalProps = {
	isShow: boolean;
	title: string;
	detailModel?: UserGroupModel;
	isSaving: boolean | false;
	onClose: () => void;
	onSubmit: (data: UserGroupModel) => void;
};

const UsersGroupDetailModal = (props: IUsersGroupDetailModalProps) => {
	const { isShow, title, detailModel, onSubmit, onClose, isSaving } = props;
	const isAddMode = !detailModel;
	const [initialValues, setInitialValues] = useState<UserGroupModel>(detailModel || df_UserGroupModel);

	const {
		handleSubmit,
		reset,
		formState: { errors, isSubmitting },
	} = useForm<UserGroupModel>({
		resolver: yupResolver(valid_UserGroupModel()),
		mode: 'all',
		defaultValues: initialValues,
	});

	const onSubmitHandler = (values: UserGroupModel) => {
		onSubmit(values);
	};

	useEffect(() => {
		if (initialValues) {
			reset(initialValues);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValues, reset]);

	return (
		<>
			<ModalDraggable show={isShow} sizeClass='sm' type='primary' onClose={onClose}>
				<ModalHeader classDragg={true} title={title} onClose={() => onClose()} />
				<ModalBody>
					<BaseForm onSubmit={handleSubmit(onSubmitHandler)}>
						<TextBox
							name={'name'}
							error={errors.name?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, name: value }));
							}}
							value={initialValues.name}
							hasValid={true}
							label='Tên tác nhân'
						/>
						<TextAreaBox
							name={'description'}
							error={errors.description?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, description: value }));
							}}
							value={initialValues.description}
							hasValid={true}
							label='Mô tả'
						/>
						<CheckedBoxListRole
							name='roleIds'
							label='Quyền hạn'
							hasValid={true}
							error={errors.roleIds?.message}
							onSelectionChanged={(value: any[]) => {
								setInitialValues((prev) => ({ ...prev, roleIds: value?.filter((x) => x.length > 0).join(',') }));
							}}
							selectedValue={initialValues.roleIds?.split(',')?.filter((x) => x.length > 0)}
						/>
						<FormGroup  label='Trạng thái'>
							<SwitchBox
								name='isActive'
								label='Áp dụng'
								error={errors.isActive?.message}
								onValueChanged={(value) => {
									setInitialValues((prev) => ({ ...prev, isActive: value }));
								}}
								value={initialValues.isActive}
								sizeClass='sm'
							/>
						</FormGroup>
					</BaseForm>
				</ModalBody>
				<ModalFooter>
					<ButtonCancel onClick={onClose} isDisabled={isSaving} />
					<ButtonSave
						type='button'
						isLoading={isSaving}
						isDisabled={isSaving}
						onClick={handleSubmit(onSubmitHandler)}
						text={`${!isAddMode ? 'Cập nhật' : 'Thêm mới'}`}
					/>
				</ModalFooter>
			</ModalDraggable>
		</>
	);
};

export default UsersGroupDetailModal;
