import React, { useEffect, useState } from 'react';
import { UploadFile } from '../../../context/models/upload/UploadFile';
import { PreviewImage } from './PreviewImage';
import no_img from '../../../assets/images/picture.png';
import './ImageBox.css';
import { uploadApi } from '../../../context/api/uploadApi';
import { Button } from '../Button';

const truncateText = (text: string, maxLength: number) => {
	if (text.length > maxLength) {
		return text.substring(0, 20) + '...' + text.substring(text.length - maxLength, text.length);
	}
	return text;
};

type IImageBoxProps = {
	name: string;
	value: string;
	onValueChanged: (path?: string) => void;
	label?: string;
	error?: string;
	sizeClass?: 'xs' | 'sm' | 'md' | 'lg';
	helpBlock?: string;
	labelSmall?: boolean;
	isDisabled?: boolean;
	hasValid?: boolean;
};

export const ImageBox = (props: IImageBoxProps) => {
	const { value, error, name, label, helpBlock, labelSmall, hasValid, onValueChanged } = props;
	const showError = error ? true : false;

	const [uploadedImage, setUploadedImage] = useState<string>();
	const [showPreview, setShowPreview] = useState<boolean>(false);

	const handleSelectImage = () => {
		const input = document.createElement('input');

		input.setAttribute('type', 'file');
		input.setAttribute('accept', 'image/*');
		input.click();

		input.onchange = async () => {
			var files = input.files ?? [];
			if (files.length > 0) {
				uploadApi.UploadImagesAsync({ files: files }).then((response) => {
					if (response && response.isSuccess) {
						const images = response?.result as UploadFile[];
						if (images) {
							setUploadedImage(images[0]?.path);
						}
					}
				});
			}
		};
	};

	useEffect(() => {
		if (uploadedImage) {
			onValueChanged(uploadedImage);
		}
	}, [uploadedImage]);

	return (
		<>
			<div className='form-group'>
				{label && (
					<div className='form-label-group'>
						<label className={`form-label ${labelSmall ? 'form-label-small' : ''}`} htmlFor={name}>
							{label} {hasValid && <em className='text-danger'>(*)</em>}
						</label>
					</div>
				)}
				<div className='form-control-wrap'>
					<div className='nk-image-box'>
						<div className='data'>
							<div className='gallery card'>
								<div className='gallery-image popup-image bg-gray-100 border border-primary text-center p-2'>
									{value ? (
										<>
											<img className='rounded h-max-100px' src={value || no_img} alt='Ảnh đại diện' />
											<div className='up_btn_actions'>
												<Button
													type='button'
													onClick={() => setShowPreview((prev) => !prev)}
													text='Preview'
													icon='icon ni ni-eye'
													sizeClass='xs'
													preset='dimoutline'
													theme='info'
												/>
												<Button
													type='button'
													onClick={() => onValueChanged()}
													text='Xóa'
													icon='icon ni ni-trash'
													sizeClass='xs'
													preset='dimoutline'
													theme='danger'
												/>
											</div>
										</>
									) : (
										<>
											<button className='btn btn-xs btn-primary' type='button' onClick={() => handleSelectImage()}>
												<em className='icon ni ni-upload'></em> <span>Upload ảnh</span>
											</button>
										</>
									)}
								</div>
							</div>
						</div>
					</div>
					{showError && <span className='form-note invalid'>{error}</span>}
					{helpBlock && <span className='form-note text-muted' dangerouslySetInnerHTML={{ __html: helpBlock }} />}
				</div>
			</div>
			{showPreview && <PreviewImage image={value} onClose={() => setShowPreview((prev) => !prev)} />}
		</>
	);
};
