import { ColumnDef } from '@tanstack/react-table';
import React from 'react';
import no_img from '../../../assets/images/picture.png';
import { ArticleItemResponse } from '../../../context/models/articles/ArticleModel';
import { TableDefault } from '../../../components/common/Table';

export const RelatedArticles = () => {
	const columns = React.useMemo<ColumnDef<ArticleItemResponse>[]>(
		() => [
			{
				header: 'Tin bài',
				cell: ({ row }) => {
					const article = row.original;
					return (
						<>
							<div className='user-card'>
								<div className='article-avatar bg-white border border-light d-none d-sm-block d-md-block'>
									<img src={article.image ?? no_img} alt='ảnh đại diện' />
								</div>
								<div className='user-info'>
									<h6 className='tb-lead'>
										<span className='fw-bold'>
											<em className='ni ni-article text-azure fs-14px'></em>
											<span className='bold _neo_article_TITLE'>{article.title}</span>
										</span>
									</h6>
								</div>
							</div>
						</>
					);
				},
				footer: (props) => props.column.id,
			},
			{
				accessorKey: 'catId',
				header: 'Chuyên mục',
				cell: ({ row }) => <span>{row.original.categoryName}</span>,
				meta: { width: 220, displayMode: 'tb-col-md' },
				footer: (props) => props.column.id,
			},
			// {
			// 	id: 'actions',
			// 	meta: { headerClass: 'nk-tb-col-tools', columnClass: 'nk-tb-col-tools ', width: 40 },
			// 	cell: ({ row, table }) => <ArticleButtonTable row={row} table={table} onShowDetail={() => onShowDetail({ isShow: true, id: row.original.id })} />,
			// },
		],
		[]
	);
	return (
		<>
			<TableDefault
				refresh={() => {}}
				columns={columns}
				data={[]}
				keyFn='id'
			/>
		</>
	);
};
