import { IRolesState } from "./IRolesState"
import { RolesActionTypes, eRolesActionTypeIds } from "./IRolesActionsTypes"
import { BaseGetActionStatus, eBaseActionStatus } from "../../base/eBaseActionStatus"
import { NotifyHelper } from "../../../utils/NotifyHelper"
import { RoleModel, RoleResponse } from "../../models/roles/RoleModel";
import { BasePaginationRespone, dfBasePagination } from "../../base/BasePaginationRespone";

const initPaginationResponse: BasePaginationRespone<RoleResponse> = { listDatas: [], pagination: dfBasePagination };
const initialState: IRolesState = {
    status: eBaseActionStatus.idle,
    allRoles: [],
    listRoles: initPaginationResponse,
    selectedIds: [],
    showConfirm: false,
    showDetail: { isShow: false }
}
const rolesReducer = (state: IRolesState = initialState, action: RolesActionTypes): IRolesState => {
    switch (action.type) {
        case eRolesActionTypeIds.GET_ALL_REQUEST:
            case eRolesActionTypeIds.GET_PAGINATION_REQUEST:
                return {
                    ...state,
                    status: BaseGetActionStatus(action)
                }
            case eRolesActionTypeIds.GET_ALL_SUCCESS:
                return {
                    ...state,
                    allRoles: action.payload,
                    status: BaseGetActionStatus(action)
                }
            case eRolesActionTypeIds.GET_PAGINATION_SUCCESS:
                return {
                    ...state,
                    listRoles: action.payload,
                    status: BaseGetActionStatus(action)
                }
            case eRolesActionTypeIds.GET_ALL_FAILURE:
                return {
                    ...state,
                    allRoles: [],
                    status: BaseGetActionStatus(action)
                }
            case eRolesActionTypeIds.GET_PAGINATION_FAILURE:
                return {
                    ...state,
                    listRoles: initPaginationResponse,
                    status: BaseGetActionStatus(action)
                }
            case eRolesActionTypeIds.CHANGE_SELECTED_IDS:
                return {
                    ...state,
                    selectedIds: action.payload,
                }
            case eRolesActionTypeIds.SHOW_DETAIL:
                return {
                    ...state,
                    showDetail: action.payload
                }
            case eRolesActionTypeIds.SHOW_CONFIRM:
                return {
                    ...state,
                    showConfirm: action.payload
                }
        case eRolesActionTypeIds.SAVE_SAVING:
                return {
                    ...state,
                    status: BaseGetActionStatus(action)
                }
        case eRolesActionTypeIds.DELETE_SAVING:
                return {
                    ...state,
                    status: BaseGetActionStatus(action)
                }
            case eRolesActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success(action.payload ?? 'Lưu quyền thành công.');
                return {
                    ...state,
                    showDetail: { isShow: false },
                    status: BaseGetActionStatus(action),
                }
            case eRolesActionTypeIds.DELETE_SUCCESS:
                NotifyHelper.Success(action.payload.toString() || 'Xóa quyền thành công');
                return {
                    ...state,
                    selectedIds: [],
                    showConfirm: false,
                    status: BaseGetActionStatus(action),
                }
            case eRolesActionTypeIds.SAVE_FAILURE:
                NotifyHelper.Error(action.payload.toString());
                return {
                    ...state,
                    showDetail: { isShow: false },
                    status: BaseGetActionStatus(action),
                }
            case eRolesActionTypeIds.DELETE_FAILURE:
                NotifyHelper.Error(action.payload.toString());
                return {
                    ...state,
                    selectedIds: [],
                    showConfirm: false,
                    status: BaseGetActionStatus(action),
                }
            case eRolesActionTypeIds.NEED_RELOAD:
                return {
                    ...state,
                    status: BaseGetActionStatus(action)
                }
        default:
            return state;
    }
}
export default rolesReducer;