import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import no_img from '../../../assets/images/picture.png';
import { Button, ButtonLink, LoadingTable } from '../../../components/base';
import DxTable, { DxLookup, DxTableColumn } from '../../../components/common/DxTable';
import { BlockCard, PageBody, PageContainer, PageHeader } from '../../../components/container';
import { PageFooter } from '../../../components/container/PageFooter';
import { useLookupArticleCategory } from '../../../components/shared/hoc-article-category/useLookupArticleCategory';
import { ArticleActionConfirmRequest } from '../../../context/models/articles/ArticleActionConfirmRequest';
import { ArticleItemResponse, ArticleModel } from '../../../context/models/articles/ArticleModel';
import { ArticleSelectRequest, df_ArticleSelectRequest } from '../../../context/models/articles/ArticleSelectRequest';
import { eArticleStatus } from '../../../context/models/articles/eArticleStatus';
import { BasePaginationRespone } from '../../../context/base/BasePaginationRespone';
import { BaseShowDetailId } from '../../../context/base/BaseShowDetail';
import { eBaseActionStatus } from '../../../context/base/eBaseActionStatus';
import { PaginationRender } from '../../../utils/PaginationHelper';
import { createUUID } from '../../../utils/createUUID';
import { ArticleHeaderButton } from '../button-actions/ArticleHeaderButton';
import { ChangeStatusConfirm } from '../change-status-confirm';
import FilterBox from '../filter-box';
import { history } from '../../../utils/history';

type IArticleTablePageProps = {
	titlePage: string;
	articleStatus: eArticleStatus;
	langCode: string;
	isOwner: boolean;
	status: eBaseActionStatus;
	paginationResponse: BasePaginationRespone<ArticleItemResponse>;
	selectedIds: string[];
	showDetail: BaseShowDetailId<string>;
	showConfirm: ArticleActionConfirmRequest;
	handleReloadData: (request: ArticleSelectRequest) => void;
	handleGetDetail: (id: string) => void;
	handleSaveData: (data: ArticleModel) => void;
	handleDeleteData: (data: string[]) => void;
	onChangeSelectedIds: (ids: string[]) => void;
	handleChangeStatus: (data: ArticleActionConfirmRequest) => void;
	onShowDetail: (data: BaseShowDetailId<string>) => void;
	onShowConfirm: (data: ArticleActionConfirmRequest) => void;
};

const ArticleTable = (props: IArticleTablePageProps) => {
	const navigate = useNavigate();
	const { article_category_all } = useLookupArticleCategory();
	const [showFilter, setShowFilter] = useState(false);
	const location = useLocation();
	const {
		titlePage,
		langCode,
		articleStatus,
		isOwner,
		status,
		selectedIds,
		showConfirm,
		paginationResponse,
		handleReloadData,
		handleChangeStatus,
		onChangeSelectedIds,
		onShowConfirm
	} = props;
	const { listDatas, pagination } = paginationResponse;
	const [filter, setFilter] = useState<ArticleSelectRequest>({ ...df_ArticleSelectRequest, status: articleStatus, langCode: langCode });

	const handleAddEditAction = (id?: string) => {
		history.replace(location.pathname);
		navigate(`${location.pathname}/edit/${id || '0'}`, { replace: false });
	};

	const handleReloadDataWithFilter = useCallback(() => {
		setShowFilter(false);
		handleReloadData(filter);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter]);

	useEffect(() => {
		handleReloadDataWithFilter();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter]);

	useEffect(() => {
		if (status === eBaseActionStatus.reload) handleReloadDataWithFilter();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	const columns = useMemo((): any => {
		let result: any = [
			<DxTableColumn
				key={createUUID()}
				dataField='title'
				caption={`Tiêu đề`}
				minWidth={300}
				fixed
				cellRender={(cell: any) => {
					const article = cell.data;
					return (
						<>
							<div className='user-card'>
								<div className='article-avatar bg-white border border-light d-none d-sm-block d-md-block'>
									<img src={article.image?.length > 0 ? article.image : no_img} alt='ảnh đại diện' />
								</div>
								<div className='user-info'>
									<h6 className='tb-lead'>
										<span className='fw-bold'>
											<em className='ni ni-article text-azure fs-14px'></em>
											<span className='bold _neo_article_TITLE'>{article.title}</span>
										</span>
									</h6>
									<p className='tb-sub  p-0 m-0 pb-1'>
										NXB: <b>{article.publishedOnDate}</b>, Độ dài: <b>{article.wordCount}</b> từ.
									</p>
									<p className='tb-sapo d-none d-sm-block d-md-block p-0 m-0 pb-1'>
										Sapo: <em>{article.initContent}</em>
									</p>

									<p className='tb-sub  p-0 m-0'>
										Tạo bởi: <b>{article.createdBy}</b>, ngày tạo: <b>{article.createdOnDate}</b>; Sửa bởi: <b>{article.lastModifiedBy}</b>,
										ngày sửa: <b>{article.lastModifiedOnDate}</b>.
									</p>
									{/* <div className='alert alert-pro alert-primary p-2 fs-11px'>
										<div className='alert-text'>
											<b>host - Nhận biên tập bài viết Gửi biên tập</b>
											<small>
												(Người gửi: <b>host</b>, thời gian gửi: <b>25 giây trước</b>)
											</small>
										</div>
									</div> */}
								</div>
							</div>
						</>
					);
				}}
			></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='catId' width={150} caption={`Chuyên mục`}>
				<DxLookup dataSource={article_category_all} valueExpr={'id'} displayExpr={'name'} />
			</DxTableColumn>,
		];
		result.push(
			<DxTableColumn
				visibleIndex={1}
				key={createUUID()}
				width={50}
				caption={'Sửa'}
				alignment='center'
				allowExporting={true}
				fixed
				cellRender={(cell: any) => {
					return <ButtonLink onClick={() => handleAddEditAction(cell.data.id)} title='Sửa' icon='ni ni-edit' theme='link' />;
				}}
			/>
		);
		return result;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [article_category_all]);

	return (
		<>
			<PageContainer>
				<PageHeader title={`Danh sách ${titlePage}`}>
					<div className='nk-fmg-actions'>
						<ul className='nk-block-tools g-3'>
							<ArticleHeaderButton
								isOwner={isOwner}
								articleStatus={articleStatus}
								selectedIds={selectedIds}
								onActionConfirm={(actionChange, selectedIds) =>
									onShowConfirm({ isShow: true, actionStatus: actionChange, ids: selectedIds })
								}
							/>
							<li>
								<Button
									text='Tạo mới'
									icon='icon ni ni-plus'
									theme='primary'
									className='d-md-inline-flex'
									onClick={() => handleAddEditAction()}
								/>
							</li>
							<li>
								<Button
									text='Lọc tin bài'
									icon='icon ni ni-search'
									theme='info'
									className='d-md-inline-flex'
									onClick={() => setShowFilter(true)}
								/>
							</li>
						</ul>
					</div>
				</PageHeader>
				<PageBody>
					<BlockCard>
						{status === eBaseActionStatus.loading && <LoadingTable />}
						{status !== eBaseActionStatus.loading && (
							<DxTable
								dataSource={listDatas}
								keyExpr='id'
								columns={columns}
								hasFixed={true}
								isLoading={status !== eBaseActionStatus.complete}
								defaultPageSize={50}
								height={window.innerHeight - 300}
								wordWrapEnabled={true}
								filters={{
									refreshDataGrid: handleReloadDataWithFilter,
								}}
								selection={{
									mode: 'multiple',
									onSelectionChanged: (e: any) => {
										onChangeSelectedIds(e.selectedRowKeys || []);
									},
									selectedRowKeys: selectedIds,
								}}
							/>
						)}
					</BlockCard>
				</PageBody>
				<PageFooter>
					{status === eBaseActionStatus.complete && (
						<PaginationRender
							onPageChange={(page) => setFilter((prev) => ({ ...prev, pageIndex: page }))}
							pagination={pagination}
							siblingCount={2}
						/>
					)}
				</PageFooter>
			</PageContainer>
			{showConfirm && showConfirm.isShow && (
				<>
					<ChangeStatusConfirm
						isShow={showConfirm.isShow}
						onClose={() => onShowConfirm({ isShow: false, actionStatus: showConfirm.actionStatus, ids: [] })}
						onConfirmStatus={() => handleChangeStatus(showConfirm)}
						isLoading={status === eBaseActionStatus.loading}
						actionStatus={showConfirm.actionStatus}
						selectedIds={selectedIds}
					/>
				</>
			)}
			{showFilter && (
				<FilterBox
					filterModel={filter}
					isLoading={status === eBaseActionStatus.loading}
					isShow={showFilter}
					onClose={() => setShowFilter(false)}
					onSubmit={(filterChanged) => setFilter((prev) => ({ ...prev, ...filterChanged }))}
				/>
			)}
		</>
	);
};

export default ArticleTable;
