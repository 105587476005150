import React, { useMemo } from 'react';
import { Button } from '../../../components/base';
import { ProductActionRule, eProductStatus } from '../../../context/models/products/eProductStatus';
type IProductHeaderButtonProps = {
	productStatus: eProductStatus;
	isOwner?: boolean;
	selectedIds: number[];
	onActionConfirm: (actionChange: eProductStatus, selectedIds: number[]) => void;
};

export const ProductHeaderButton = ({ productStatus, selectedIds, onActionConfirm, isOwner }: IProductHeaderButtonProps) => {
	const actionsRule = useMemo(() => {
		return ProductActionRule({ status: productStatus, isOwner: isOwner || false });
	}, [productStatus, isOwner]);

	return (
		<>
			{actionsRule.includes(eProductStatus.DELETED) && (
				<>
					<li>
						<Button
							text={`Xóa (${selectedIds.length})`}
							icon='icon ni ni-trash'
							className='d-md-inline-flex'
							theme='danger'
							isDisabled={selectedIds.length <= 0}
							onClick={() => onActionConfirm(eProductStatus.DELETED, selectedIds)}
						/>
					</li>
				</>
			)}
			{actionsRule.includes(eProductStatus.RESTORE) && (
				<>
					<li>
						<Button
							text={`Khôi phục (${selectedIds.length})`}
							icon='icon ni ni-history'
							className='d-md-inline-flex'
							theme='secondary'
							isDisabled={selectedIds.length <= 0}
							onClick={() => onActionConfirm(eProductStatus.RESTORE, selectedIds)}
						/>
					</li>
				</>
			)}
			{actionsRule.includes(eProductStatus.REMOVED) && (
				<>
					<li>
						<Button
							text={`Ngừng bán (${selectedIds.length})`}
							icon='icon ni ni-spark-off-fill'
							className='d-md-inline-flex'
							theme='danger'
							isDisabled={selectedIds.length <= 0}
							onClick={() => onActionConfirm(eProductStatus.REMOVED, selectedIds)}
						/>
					</li>
				</>
			)}
			{actionsRule.includes(eProductStatus.PUBLISHED) && (
				<>
					<li>
						<Button
							text={`Đăng bán (${selectedIds.length})`}
							icon='icon ni ni-spark-fill'
							className='d-md-inline-flex'
							theme='primary'
							isDisabled={selectedIds.length <= 0}
							onClick={() => onActionConfirm(eProductStatus.PUBLISHED, selectedIds)}
						/>
					</li>
				</>
			)}
		</>
	);
};
