import React, { useState } from 'react';

export type IPasswordBoxProps = {
	name: string;
	value: string;
	onValueChanged: (value: string) => void;
	label?: string;
	error?: string;
	sizeClass?: 'xs' | 'sm' | 'md' | 'lg';
	helpBlock?: string;
	placeholder?: string;
	labelSmall?: boolean;
	isDisabled?: boolean;
	hasValid?: boolean;
};

export const PasswordBox = (props: IPasswordBoxProps) => {
	const [showPassword, setShowPassword] = useState(false);
	const {
		value,
		error,
		name,
		label,
		sizeClass,
		isDisabled,
		helpBlock,
		labelSmall,
		hasValid,
		placeholder,
		onValueChanged,
	} = props;
	const showError = error ? true : false;
	return (
		<>
			<div className='form-group'>
				{label && (
					<div className='form-label-group'>
						<label className={`form-label ${labelSmall ? 'form-label-small' : ''}`} htmlFor={name}>
							{label} {hasValid && <em className='text-danger'>(*)</em>}
						</label>
					</div>
				)}
				<div className='form-control-wrap'>
					<button
						type='button'
						tabIndex={1}
						className={`btn btn-link form-icon form-icon-right passcode-switch ${sizeClass || 'sm'}`}
						onClick={() => setShowPassword((prev) => !prev)}
					>
						<em className={`passcode-icon icon-show icon ni ${showPassword ? 'ni-eye-off' : 'ni-eye'}`}></em>
					</button>
					<input
						id={name}
						name={name}
						type={showPassword ? 'text' : 'password'}
						aria-invalid={error ? 'true' : 'false'}
						value={value}
						disabled={isDisabled}
						placeholder={placeholder}
						tabIndex={0}
						onChange={(e) => onValueChanged(e.target.value)}
						className={`form-control form-control-${sizeClass || 'sm'} ${showError ? 'error' : ''}`}
					/>
					{showError && <span className='form-note invalid'>{error}</span>}
					{helpBlock && <span className='form-note text-muted' dangerouslySetInnerHTML={{ __html: helpBlock }} />}
				</div>
			</div>
		</>
	);
};
