export enum eArticleArchives {
    DOC_NHIEU = "DOC_NHIEU",
    DOC_NHIEU_MUC = "DOC_NHIEU_MUC",
    DONG_SU_KIEN = "DONG_SU_KIEN",
    NOI_BAT_MUC = "NOI_BAT_MUC",
    NOI_BAT_TRANG_CHU = "NOI_BAT_TRANG_CHU",
    TIEU_DIEM_MUC = "TIEU_DIEM_MUC",
    TIEU_DIEM_TRANG_CHU = "TIEU_DIEM_TRANG_CHU",
    TIN_NONG = "TIN_NONG",
    XU_HUONG_DOC = "XU_HUONG_DOC",
}

export const eArticleArchivesData = [
    { Code: "DOC_NHIEU", Name: "Đọc nhiều" },
    { Code: "DOC_NHIEU_MUC", Name: "Đọc nhiều mục" },
    { Code: "DONG_SU_KIEN", Name: "Dòng sự kiện" },
    { Code: "NOI_BAT_MUC", Name: "Nổi bật mục" },
    { Code: "NOI_BAT_TRANG_CHU", Name: "Nổi bật trang chủ" },
    { Code: "TIEU_DIEM_MUC", Name: "Tiêu điểm mục" },
    { Code: "TIEU_DIEM_TRANG_CHU", Name: "Tiêu điểm trang chủ" },
    { Code: "TIN_NONG", Name: "Tin nóng/mới" },
    { Code: "XU_HUONG_DOC", Name: "Xu hướng đọc" }
]