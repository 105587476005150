export enum eArticleGenres {
    CONTENT = "CONTENT",
    PHOTO = "PHOTO",
    VIDEO = "VIDEO"
}

export const eArticleGenresData = [
    { Code: "CONTENT", Name: "Nội dung" },
    { Code: "PHOTO", Name: "Tin ảnh" },
    { Code: "VIDEO", Name: "Video" },
]