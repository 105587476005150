import { BasePaginationRespone } from "../../base/BasePaginationRespone";
import { BaseSelectRequest } from "../../base/BaseSelectRequest";
import { BaseShowDetailId } from "../../base/BaseShowDetail";
import { IActionTypeBase } from "../../base/IBaseActionTypes";
import { ProductChangeStatusRequest } from "../../models/products/ProductChangeStatusRequest";
import { ProductModel } from "../../models/products/ProductModel";
import { ProductSelectRequest } from "../../models/products/ProductSelectRequest";
import { ProductActionConfirmRequest } from "../../models/products/ProductActionConfirmRequest";

export enum eProductActionTypeIds {
    GET_PAGINATION_REQUEST = "Product_GET_PAGINATION_REQUEST",
    GET_PAGINATION_SUCCESS = "Product_GET_PAGINATION_SUCCESS",
    GET_PAGINATION_FAILURE = "Product_GET_PAGINATION_FAILURE",

    GET_BY_ACTION_REQUEST = "Product_GET_BY_ACTION_REQUEST",
    GET_BY_ACTION_SUCCESS = "Product_GET_BY_ACTION_SUCCESS",
    GET_BY_ACTION_FAILURE = "Product_GET_BY_ACTION_FAILURE",

    GET_BY_OWNER_ACTION_REQUEST = "Product_GET_BY_OWNER_ACTION_REQUEST",
    GET_BY_OWNER_ACTION_SUCCESS = "Product_GET_BY_OWNER_ACTION_SUCCESS",
    GET_BY_OWNER_ACTION_FAILURE = "Product_GET_BY_OWNER_ACTION_FAILURE",

    GET_PUBLISHED_REQUEST = "Product_GET_PUBLISHED_REQUEST",
    GET_PUBLISHED_SUCCESS = "Product_GET_PUBLISHED_SUCCESS",
    GET_PUBLISHED_FAILURE = "Product_GET_PUBLISHED_FAILURE",

    GET_DETAIL_REQUEST = "Product_GET_DETAIL_REQUEST",
    GET_DETAIL_SUCCESS = "Product_GET_DETAIL_SUCCESS",
    GET_DETAIL_FAILURE = "Product_GET_DETAIL_FAILURE",

    SAVE_REQUEST = "Product_SAVE_REQUEST",
    SAVE_SUCCESS = "Product_SAVE_SUCCESS",
    SAVE_FAILURE = "Product_SAVE_FAILURE",

    DELETE_REQUEST = "Product_DELETE_REQUEST",
    DELETE_SUCCESS = "Product_DELETE_SUCCESS",
    DELETE_FAILURE = "Product_DELETE_FAILURE",

    CHANGE_STATUS_REQUEST = "Product_CHANGE_STATUS_REQUEST",
    CHANGE_STATUS_SUCCESS = "Product_CHANGE_STATUS_SUCCESS",
    CHANGE_STATUS_FAILURE = "Product_CHANGE_STATUS_FAILURE",

    NEED_RELOAD = "Product_NEED_RELOAD",

    CHANGE_SELECTED_IDS = "Product_CHANGE_SELECTED_IDS",
    SHOW_DETAIL = "Product_SHOW_DETAIL",
    CLOSE_DETAIL = "Product_CLOSE_DETAIL",
    SHOW_CONFIRM = "Product_SHOW_CONFIRM",
    CLOSE_CONFIRM = "Product_CLOSE_CONFIRM",
}

export interface ProductAction_GETPAGINATION_Request extends IActionTypeBase<eProductActionTypeIds.GET_PAGINATION_REQUEST, BaseSelectRequest> { }
export interface ProductAction_GETPAGINATION_Success extends IActionTypeBase<eProductActionTypeIds.GET_PAGINATION_SUCCESS, BasePaginationRespone<ProductModel>> { }
export interface ProductAction_GETPAGINATION_Failure extends IActionTypeBase<eProductActionTypeIds.GET_PAGINATION_FAILURE, Error | string> { }

export interface ProductAction_GETBY_ACTION_Request extends IActionTypeBase<eProductActionTypeIds.GET_BY_ACTION_REQUEST, ProductSelectRequest> { }
export interface ProductAction_GETBY_ACTION_Success extends IActionTypeBase<eProductActionTypeIds.GET_BY_ACTION_SUCCESS, BasePaginationRespone<ProductModel>> { }
export interface ProductAction_GETBY_ACTION_Failure extends IActionTypeBase<eProductActionTypeIds.GET_BY_ACTION_FAILURE, Error | string> { }

export interface ProductAction_GETBY_OWNER_ACTION_Request extends IActionTypeBase<eProductActionTypeIds.GET_BY_OWNER_ACTION_REQUEST, ProductSelectRequest> { }
export interface ProductAction_GETBY_OWNER_ACTION_Success extends IActionTypeBase<eProductActionTypeIds.GET_BY_OWNER_ACTION_SUCCESS, BasePaginationRespone<ProductModel>> { }
export interface ProductAction_GETBY_OWNER_ACTION_Failure extends IActionTypeBase<eProductActionTypeIds.GET_BY_OWNER_ACTION_FAILURE, Error | string> { }

export interface ProductAction_GETPUBLISHED_Request extends IActionTypeBase<eProductActionTypeIds.GET_PUBLISHED_REQUEST, ProductSelectRequest> { }
export interface ProductAction_GETPUBLISHED_Success extends IActionTypeBase<eProductActionTypeIds.GET_PUBLISHED_SUCCESS, BasePaginationRespone<ProductModel>> { }
export interface ProductAction_GETPUBLISHED_Failure extends IActionTypeBase<eProductActionTypeIds.GET_PUBLISHED_FAILURE, Error | string> { }

export interface ProductAction_GET_DETAIL_Request extends IActionTypeBase<eProductActionTypeIds.GET_DETAIL_REQUEST, number> { }
export interface ProductAction_GET_DETAIL_Success extends IActionTypeBase<eProductActionTypeIds.GET_DETAIL_SUCCESS, ProductModel | undefined> { }
export interface ProductAction_GET_DETAIL_Failure extends IActionTypeBase<eProductActionTypeIds.GET_DETAIL_FAILURE, Error | string> { }

export interface ProductAction_SAVE_Request extends IActionTypeBase<eProductActionTypeIds.SAVE_REQUEST, ProductModel> { }
export interface ProductAction_SAVE_Success extends IActionTypeBase<eProductActionTypeIds.SAVE_SUCCESS, string> { }
export interface ProductAction_SAVE_Failure extends IActionTypeBase<eProductActionTypeIds.SAVE_FAILURE, Error | string> { }

export interface ProductAction_DELETE_Request extends IActionTypeBase<eProductActionTypeIds.DELETE_REQUEST, number[]> { }
export interface ProductAction_DELETE_Success extends IActionTypeBase<eProductActionTypeIds.DELETE_SUCCESS, string> { }
export interface ProductAction_DELETE_Failure extends IActionTypeBase<eProductActionTypeIds.DELETE_FAILURE, Error | string> { }

export interface ProductAction_CHANGE_STATUS_Request extends IActionTypeBase<eProductActionTypeIds.CHANGE_STATUS_REQUEST, ProductChangeStatusRequest> { }
export interface ProductAction_CHANGE_STATUS_Success extends IActionTypeBase<eProductActionTypeIds.CHANGE_STATUS_SUCCESS, string> { }
export interface ProductAction_CHANGE_STATUS_Failure extends IActionTypeBase<eProductActionTypeIds.CHANGE_STATUS_FAILURE, Error | string> { }

export interface ProductAction_RELOAD extends IActionTypeBase<eProductActionTypeIds.NEED_RELOAD, undefined> { }

export interface ProductAction_SHOW_DETAIL extends IActionTypeBase<eProductActionTypeIds.SHOW_DETAIL, BaseShowDetailId<number>> { }
export interface ProductAction_CHANGE_SELECTED_IDS extends IActionTypeBase<eProductActionTypeIds.CHANGE_SELECTED_IDS, number[]> { }
export interface ProductAction_SHOW_CONFIRM extends IActionTypeBase<eProductActionTypeIds.SHOW_CONFIRM, ProductActionConfirmRequest> { }

export type ProductActionTypes = ProductAction_GETPAGINATION_Request | ProductAction_GETPAGINATION_Success | ProductAction_GETPAGINATION_Failure |
    ProductAction_GETBY_ACTION_Request | ProductAction_GETBY_ACTION_Success | ProductAction_GETBY_ACTION_Failure |
    ProductAction_GETBY_OWNER_ACTION_Request | ProductAction_GETBY_OWNER_ACTION_Success | ProductAction_GETBY_OWNER_ACTION_Failure |
    ProductAction_GETPUBLISHED_Request | ProductAction_GETPUBLISHED_Success | ProductAction_GETPUBLISHED_Failure |
    ProductAction_GET_DETAIL_Request | ProductAction_GET_DETAIL_Success | ProductAction_GET_DETAIL_Failure |
    ProductAction_SAVE_Request | ProductAction_SAVE_Success | ProductAction_SAVE_Failure |
    ProductAction_DELETE_Request | ProductAction_DELETE_Success | ProductAction_DELETE_Failure |
    ProductAction_CHANGE_STATUS_Request | ProductAction_CHANGE_STATUS_Success | ProductAction_CHANGE_STATUS_Failure |
    ProductAction_RELOAD | ProductAction_SHOW_DETAIL | ProductAction_CHANGE_SELECTED_IDS | ProductAction_SHOW_CONFIRM 