import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import no_img from '../../../assets/images/picture.png';
import { Button, ButtonLink, LoadingTable } from '../../../components/base';
import DxTable, { DxLookup, DxTableColumn } from '../../../components/common/DxTable';
import { BlockCard, PageBody, PageContainer, PageHeader } from '../../../components/container';
import { PageFooter } from '../../../components/container/PageFooter';
import { useLookupProductCategory } from '../../../components/shared/hoc-product-category/useLookupProductCategory';
import { ProductActionConfirmRequest } from '../../../context/models/products/ProductActionConfirmRequest';
import { ProductModel } from '../../../context/models/products/ProductModel';
import { ProductSelectRequest, df_ProductSelectRequest } from '../../../context/models/products/ProductSelectRequest';
import { eProductStatus } from '../../../context/models/products/eProductStatus';
import { BasePaginationRespone } from '../../../context/base/BasePaginationRespone';
import { BaseShowDetailId } from '../../../context/base/BaseShowDetail';
import { eBaseActionStatus } from '../../../context/base/eBaseActionStatus';
import { PaginationRender } from '../../../utils/PaginationHelper';
import { createUUID } from '../../../utils/createUUID';
import { ProductHeaderButton } from '../button-actions/ProductHeaderButton';
import { ChangeStatusConfirm } from '../change-status-confirm';
import FilterBox from '../filter-box';
import { history } from '../../../utils/history';

type IProductTablePageProps = {
	titlePage: string;
	productStatus: eProductStatus;
	langCode: string;
	isOwner: boolean;
	status: eBaseActionStatus;
	paginationResponse: BasePaginationRespone<ProductModel>;
	selectedIds: number[];
	showDetail: BaseShowDetailId<number>;
	showConfirm: ProductActionConfirmRequest;
	handleReloadData: (request: ProductSelectRequest) => void;
	handleGetDetail: (id: number) => void;
	handleSaveData: (data: ProductModel) => void;
	handleDeleteData: (data: number[]) => void;
	onChangeSelectedIds: (ids: number[]) => void;
	handleChangeStatus: (data: ProductActionConfirmRequest) => void;
	onShowDetail: (data: BaseShowDetailId<number>) => void;
	onShowConfirm: (data: ProductActionConfirmRequest) => void;
};

const ProductTable = (props: IProductTablePageProps) => {
	const navigate = useNavigate();
	const { product_category_all } = useLookupProductCategory();
	const [showFilter, setShowFilter] = useState(false);
	const location = useLocation();
	const {
		titlePage,
		langCode,
		productStatus,
		isOwner,
		status,
		selectedIds,
		showConfirm,
		paginationResponse,
		handleReloadData,
		handleChangeStatus,
		onChangeSelectedIds,
		onShowConfirm,
	} = props;
	const { listDatas, pagination } = paginationResponse;
	const [filter, setFilter] = useState<ProductSelectRequest>({ ...df_ProductSelectRequest, status: productStatus, langCode: langCode });

	const handleAddEditAction = (id?: string) => {
		history.replace(location.pathname);
		navigate(`${location.pathname}/edit/${id || '0'}`, { replace: false });
	};

	const handleReloadDataWithFilter = useCallback(() => {
		setShowFilter(false);
		handleReloadData(filter);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter]);

	useEffect(() => {
		handleReloadDataWithFilter();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter]);

	useEffect(() => {
		if (status === eBaseActionStatus.reload) handleReloadDataWithFilter();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	const columns = useMemo((): any => {
		let result: any = [
			<DxTableColumn
				key={createUUID()}
				dataField='image'
				caption={`Ảnh`}
				width={100}
				fixed
				cellRender={(cell: any) => {
					return (
						<>
							<div className='user-card'>
								<div className='product-avatar bg-white border border-light d-none d-sm-block d-md-block'>
									<img src={cell.value?.length > 0 ? cell.value : no_img} alt='ảnh đại diện' />
								</div>
							</div>
						</>
					);
				}}
			></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='sku' caption={`SKU`} width={100} fixed></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='name' caption={`Sản phẩm`} minWidth={300} fixed></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='catId' width={150} caption={`Danh mục`}>
				<DxLookup dataSource={product_category_all} valueExpr={'id'} displayExpr={'name'} />
			</DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='price' dataType={'number'} format={'currency'}  width={150} caption={`Giá bán`}></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='priceOnSale' width={150} caption={`Giá giảm`}></DxTableColumn>,
		];
		result.push(
			<DxTableColumn
				visibleIndex={1}
				key={createUUID()}
				width={50}
				caption={'Sửa'}
				alignment='center'
				allowExporting={true}
				fixed
				cellRender={(cell: any) => {
					return <ButtonLink onClick={() => handleAddEditAction(cell.data.id)} title='Sửa' icon='ni ni-edit' theme='link' />;
				}}
			/>
		);
		return result;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [product_category_all]);

	return (
		<>
			<PageContainer>
				<PageHeader title={`Danh sách ${titlePage}`}>
					<div className='nk-fmg-actions'>
						<ul className='nk-block-tools g-3'>
							<ProductHeaderButton
								isOwner={isOwner}
								productStatus={productStatus}
								selectedIds={selectedIds}
								onActionConfirm={(actionChange, selectedIds) =>
									onShowConfirm({ isShow: true, actionStatus: actionChange, ids: selectedIds })
								}
							/>
							<li>
								<Button
									text='Tạo mới'
									icon='icon ni ni-plus'
									theme='primary'
									className='d-md-inline-flex'
									onClick={() => handleAddEditAction()}
								/>
							</li>
							<li>
								<Button
									text='Lọc sản phẩm'
									icon='icon ni ni-search'
									theme='info'
									className='d-md-inline-flex'
									onClick={() => setShowFilter(true)}
								/>
							</li>
						</ul>
					</div>
				</PageHeader>
				<PageBody>
					<BlockCard>
						{status === eBaseActionStatus.loading && <LoadingTable />}
						{status !== eBaseActionStatus.loading && (
							<DxTable
								dataSource={listDatas}
								keyExpr='id'
								columns={columns}
								hasFixed={true}
								isLoading={status !== eBaseActionStatus.complete}
								defaultPageSize={50}
								height={window.innerHeight - 300}
								wordWrapEnabled={true}
								filters={{
									refreshDataGrid: handleReloadDataWithFilter,
								}}
								selection={{
									mode: 'multiple',
									onSelectionChanged: (e: any) => {
										onChangeSelectedIds(e.selectedRowKeys || []);
									},
									selectedRowKeys: selectedIds,
								}}
							/>
						)}
					</BlockCard>
				</PageBody>
				<PageFooter>
					{status === eBaseActionStatus.complete && (
						<PaginationRender
							onPageChange={(page) => setFilter((prev) => ({ ...prev, pageIndex: page }))}
							pagination={pagination}
							siblingCount={2}
						/>
					)}
				</PageFooter>
			</PageContainer>
			{showConfirm && showConfirm.isShow && (
				<>
					<ChangeStatusConfirm
						isShow={showConfirm.isShow}
						onClose={() => onShowConfirm({ isShow: false, actionStatus: showConfirm.actionStatus, ids: [] })}
						onConfirmStatus={() => handleChangeStatus(showConfirm)}
						isLoading={status === eBaseActionStatus.loading}
						actionStatus={showConfirm.actionStatus}
						selectedIds={selectedIds}
					/>
				</>
			)}
			{showFilter && (
				<FilterBox
					filterModel={filter}
					isLoading={status === eBaseActionStatus.loading}
					isShow={showFilter}
					onClose={() => setShowFilter(false)}
					onSubmit={(filterChanged) => setFilter((prev) => ({ ...prev, ...filterChanged }))}
				/>
			)}
		</>
	);
};

export default ProductTable;
