import { BaseShowDetail } from "../../base/BaseShowDetail";
import { IActionTypeBase } from "../../base/IBaseActionTypes";
import { MenuItemResponse } from "../../models/menus/MenuItemResponse";
import { MenuRemoveRoleRequest, MenuUpdateRoleMultipleRequest, MenuUpdateRoleRequest } from "../../models/menus/MenuUpdateRoleRequest";

export enum eMenusActionTypeIds {
    GET_ALL_REQUEST = "MENUS_GET_ALL_REQUEST",
    GET_ALL_SUCCESS = "MENUS_GET_ALL_SUCCESS",
    GET_ALL_FAILURE = "MENUS_GET_ALL_FAILURE",

    SHOW_CHANGE_ROLE = "MENUS_SHOW_CHANGE_ROLE",
    CHANGE_ROLE_REQUEST = "MENUS_CHANGE_ROLE_REQUEST",
    CHANGE_ROLE_SUCCESS = "MENUS_CHANGE_ROLE_SUCCESS",
    CHANGE_ROLE_FAILURE = "MENUS_CHANGE_ROLE_FAILURE",

    SHOW_CHANGE_ROLE_MULTI = "MENUS_SHOW_CHANGE_ROLE_MULTI",
    CHANGE_ROLE_MULTI_REQUEST = "MENUS_CHANGE_ROLE_MULTI_REQUEST",
    CHANGE_ROLE_MULTI_SUCCESS = "MENUS_CHANGE_ROLE_MULTI_SUCCESS",
    CHANGE_ROLE_MULTI_FAILURE = "MENUS_CHANGE_ROLE_MULTI_FAILURE",

    REMOVE_ROLE_REQUEST = "MENUS_REMOVE_ROLE_REQUEST",
    REMOVE_ROLE_SUCCESS = "MENUS_REMOVE_ROLE_SUCCESS",
    REMOVE_ROLE_FAILURE = "MENUS_REMOVE_ROLE_FAILURE",

    CHANGE_SELECTED_IDS = "MENUS_CHANGE_SELECTED_IDS",
    SHOW_CONFIRM = "MENUS_SHOW_CONFIRM",
    NEED_RELOAD = "MENUS_NEED_RELOAD",
}

export interface MenusAction_GETALL_Request extends IActionTypeBase<eMenusActionTypeIds.GET_ALL_REQUEST, undefined> { }
export interface MenusAction_GETALL_Success extends IActionTypeBase<eMenusActionTypeIds.GET_ALL_SUCCESS, MenuItemResponse[]> { }
export interface MenusAction_GETALL_Failure extends IActionTypeBase<eMenusActionTypeIds.GET_ALL_FAILURE, Error | string> { }

export interface MenusAction_SHOW_CHANGE_ROLE extends IActionTypeBase<eMenusActionTypeIds.SHOW_CHANGE_ROLE, BaseShowDetail<MenuItemResponse>> { }
export interface MenusAction_CHANGE_ROLE_Request extends IActionTypeBase<eMenusActionTypeIds.CHANGE_ROLE_REQUEST, MenuUpdateRoleRequest> { }
export interface MenusAction_CHANGE_ROLE_Success extends IActionTypeBase<eMenusActionTypeIds.CHANGE_ROLE_SUCCESS, string> { }
export interface MenusAction_CHANGE_ROLE_Failure extends IActionTypeBase<eMenusActionTypeIds.CHANGE_ROLE_FAILURE, Error | string> { }

export interface MenusAction_SHOW_CHANGE_ROLE_MULTI extends IActionTypeBase<eMenusActionTypeIds.SHOW_CHANGE_ROLE_MULTI, boolean> { }
export interface MenusAction_CHANGE_ROLE_MULTI_Request extends IActionTypeBase<eMenusActionTypeIds.CHANGE_ROLE_MULTI_REQUEST, MenuUpdateRoleMultipleRequest> { }
export interface MenusAction_CHANGE_ROLE_MULTI_Success extends IActionTypeBase<eMenusActionTypeIds.CHANGE_ROLE_MULTI_SUCCESS, string> { }
export interface MenusAction_CHANGE_ROLE_MULTI_Failure extends IActionTypeBase<eMenusActionTypeIds.CHANGE_ROLE_MULTI_FAILURE, Error | string> { }

export interface MenusAction_REMOVE_ROLE_Request extends IActionTypeBase<eMenusActionTypeIds.REMOVE_ROLE_REQUEST, MenuRemoveRoleRequest> { }
export interface MenusAction_REMOVE_ROLE_Success extends IActionTypeBase<eMenusActionTypeIds.REMOVE_ROLE_SUCCESS, string> { }
export interface MenusAction_REMOVE_ROLE_Failure extends IActionTypeBase<eMenusActionTypeIds.REMOVE_ROLE_FAILURE, Error | string> { }

export interface MenusAction_CHANGE_SELECTED_IDS extends IActionTypeBase<eMenusActionTypeIds.CHANGE_SELECTED_IDS, number[]> { }
export interface MenusAction_SHOW_CONFIRM extends IActionTypeBase<eMenusActionTypeIds.SHOW_CONFIRM, boolean> { }
export interface MenusAction_RELOAD extends IActionTypeBase<eMenusActionTypeIds.NEED_RELOAD, undefined> { }

export type MenusActionTypes = MenusAction_GETALL_Request | MenusAction_GETALL_Success | MenusAction_GETALL_Failure |
    MenusAction_SHOW_CHANGE_ROLE | MenusAction_CHANGE_ROLE_Request | MenusAction_CHANGE_ROLE_Success | MenusAction_CHANGE_ROLE_Failure |
    MenusAction_SHOW_CHANGE_ROLE_MULTI | MenusAction_CHANGE_ROLE_MULTI_Request | MenusAction_CHANGE_ROLE_MULTI_Success | MenusAction_CHANGE_ROLE_MULTI_Failure |
    MenusAction_REMOVE_ROLE_Request | MenusAction_REMOVE_ROLE_Success | MenusAction_REMOVE_ROLE_Failure |
    MenusAction_CHANGE_SELECTED_IDS | MenusAction_SHOW_CONFIRM | MenusAction_RELOAD