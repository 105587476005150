export const jwtTokenHelper = {
    SET: SET_TOKEN(),
    GET: GET_TOKEN(),
    CLEAR: CLEAR_TOKEN(),
    LANGCODE: GETLANG(),
}

function SET_TOKEN() {
    return (jwtToken: string) => {
        if (jwtToken && jwtToken !== "") {
            localStorage.jwtToken = jwtToken.split(' ')[0];
            localStorage.refreshToken = jwtToken.split(' ')[1];
        }
    }
}

function GET_TOKEN() {
    return (key?: string) => {
        const value = !key ? localStorage.jwtToken : localStorage.refreshToken;
        return value;
    }
}

function CLEAR_TOKEN() {
    return () => {
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('refreshToken');
    }
}

function GETLANG() {
    return (langCode?: string) => {
        let currentLang = 'vi-vn';
        if (langCode && langCode !== "") {
            currentLang = langCode;
            localStorage.langCode = langCode;
        }else{
            if(localStorage.langCode) currentLang = localStorage.langCode
        }
        return currentLang;
    }
}