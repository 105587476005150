import { IRoleScopesState } from "./IRoleScopesState"
import { BaseGetActionStatus, eBaseActionStatus } from "../../base/eBaseActionStatus"
import { RoleScopesActionTypes, eRoleScopesActionTypeIds } from "./IRoleScopesActionsTypes"

const initialState: IRoleScopesState = {
    status: eBaseActionStatus.idle,
    allRoleScopes: [],
}
const roleScopesReducer = (state: IRoleScopesState = initialState, action: RoleScopesActionTypes): IRoleScopesState => {
    switch (action.type) {
        case eRoleScopesActionTypeIds.GET_ALL_REQUEST:
            return {
                ...state,
                status: BaseGetActionStatus(action)
            }
        case eRoleScopesActionTypeIds.GET_ALL_SUCCESS:
            return {
                ...state,
                allRoleScopes: action.payload.data,
                status: BaseGetActionStatus(action)
            }
        case eRoleScopesActionTypeIds.GET_ALL_FAILURE:
            return {
                ...state,
                allRoleScopes: [],
                status: BaseGetActionStatus(action)
            }
        default:
            return state;
    }
}
export default roleScopesReducer;