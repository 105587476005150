import { all, call, put, fork, takeEvery } from "redux-saga/effects";
import { actions } from "../rootActions";
import { ArticleCategoryAction_DELETE_Request, ArticleCategoryAction_GETALL_Request, ArticleCategoryAction_GETPAGINATION_Request, ArticleCategoryAction_SAVE_Request, eArticleCategoryActionTypeIds } from "./IArticleCategoryActionsTypes";
import { BaseResponse } from "../../base/BaseResponse";
import { rootApi } from "../../api/rootApi";
import { ArticleCategoryModel } from "../../models/articles-category/ArticleCategoryModel";

const _sagaApi = rootApi.articleCategory;

function* onLoadAllArticleCategory(action: ArticleCategoryAction_GETALL_Request) {
    try {
        const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetAllAsync]);
        if (res.isSuccess && res.result) {
            const listArticleCategory = res.result as ArticleCategoryModel[];
            yield put(actions.articleCategory.getAllSuccess(listArticleCategory));
        }
        else {
            yield put(actions.articleCategory.getAllFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.articleCategory.getAllFailure(error || 'Có lỗi'));
    }
}

function* onLoadPaginationArticleCategory(action: ArticleCategoryAction_GETPAGINATION_Request) {
    try {
        const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetPaginationAsync], action.payload);
        if (res.isSuccess && res.result) {
            const listArticleCategory = res.result as ArticleCategoryModel[];
            yield put(actions.articleCategory.getPaginationSuccess({ listDatas: listArticleCategory, pagination: res.pagination }));
        }
        else {
            yield put(actions.articleCategory.getPaginationFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.articleCategory.getPaginationFailure(error || 'Có lỗi'));
    }
}

function* onSaveArticleCategory(action: ArticleCategoryAction_SAVE_Request) {
    try {
        if (action.payload.id > 0) {
            const res: BaseResponse = yield call([_sagaApi, _sagaApi.PutAsync], action.payload);
            if (res.isSuccess) {
                yield put(actions.articleCategory.saveSuccess(res.message));
                yield put(actions.articleCategory.needReload());
            }
            else {
                yield put(actions.articleCategory.saveFailure(res.message));
            }
        } else {
            const res: BaseResponse = yield call([_sagaApi, _sagaApi.PostAsync], action.payload);
            if (res.isSuccess) {
                yield put(actions.articleCategory.saveSuccess(res.message));
                yield put(actions.articleCategory.needReload());
            }
            else {
                yield put(actions.articleCategory.saveFailure(res.message));
            }
        }
    } catch (error: any) {
        yield put(actions.articleCategory.saveFailure(error || 'Có lỗi'));
    }
}

function* onDeleteArticleCategory(action: ArticleCategoryAction_DELETE_Request) {
    try {
        const res: BaseResponse = yield call([_sagaApi, _sagaApi.DeletesAsync], action.payload);
        if (res.isSuccess) {
            yield put(actions.articleCategory.deleteSuccess(res.message));
            yield put(actions.articleCategory.needReload());
        }
        else {
            yield put(actions.articleCategory.deleteFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.articleCategory.deleteFailure(error || 'Có lỗi'));
    }
}

function* watchOnLoadAllArticleCategory() {
    yield takeEvery(eArticleCategoryActionTypeIds.GET_ALL_REQUEST, onLoadAllArticleCategory);
}

function* watchOnLoadPaginationArticleCategory() {
    yield takeEvery(eArticleCategoryActionTypeIds.GET_PAGINATION_REQUEST, onLoadPaginationArticleCategory);
}


function* watchOnSaveArticleCategory() {
    yield takeEvery(eArticleCategoryActionTypeIds.SAVE_REQUEST, onSaveArticleCategory);
}

function* watchOnDeleteArticleCategory() {
    yield takeEvery(eArticleCategoryActionTypeIds.DELETE_REQUEST, onDeleteArticleCategory);
}

function* articleCategorySaga() {
    yield all([fork(watchOnLoadAllArticleCategory)]);
    yield all([fork(watchOnLoadPaginationArticleCategory)]);
    yield all([fork(watchOnSaveArticleCategory)]);
    yield all([fork(watchOnDeleteArticleCategory)]);
}

export default articleCategorySaga;