
import MenuPage from "./pages/accounts/menus";
import RolesPage from "./pages/accounts/roles";
import SYSAccountPage from "./pages/accounts/sys-accounts";
import UsersGroupPage from "./pages/accounts/users-group";
import ArticleCategoryPage from "./pages/articles-category";
import ArticleEditPage from "./pages/articles/edit-page";
import { APPROVINGPage, DELETEDPage, DRAFTPage, EDITINGPage, PUBLISHEDPage, REMOVEDPage, SENTAPPROVINGPage, SENTEDITINGPage, WAITAPPROVINGPage, WAITEDITINGPage } from "./pages/articles/status-page";
import { Dashboard } from "./pages/home";
import ProductCategoryPage from "./pages/products-category";
import ProductEditPage from "./pages/products/edit-page";
import { ProductDeletedPage, ProductDraftPage, ProductPublishedPage, ProductRemovedPage } from "./pages/products/status-page";
//import { SeoKeyword } from "./pages/seos/keyword";
export type AppComponent = {
    title: string,
    path: string,
    component: any
}

const AppRoutes = [
    {
        title: 'Dashboard',
        path: '/',
        component: Dashboard,
    },
    {
        title: 'Tài khoản',
        path: '/accounts',
        component: SYSAccountPage,
    },
    {
        title: 'Phan quyền chức năng',
        path: '/menu-roles',
        component: MenuPage,
    },
    {
        title: 'Tác nhân',
        path: '/users-group',
        component: UsersGroupPage,
    },
    {
        title: 'Quyền',
        path: '/roles',
        component: RolesPage,
    },
    {
        title: 'Chuyên mục',
        path: '/articles-category',
        component: ArticleCategoryPage
    },
    { title: 'Tin bài', path: '/articles-template', component: DRAFTPage, },
    { title: 'Tin bài', path: '/articles-published', component: PUBLISHEDPage, },
    { title: 'Tin bài', path: '/articles-deleted', component: DELETEDPage, },
    { title: 'Tin bài', path: '/articles-editing', component: EDITINGPage, },
    { title: 'Tin bài', path: '/articles-removed', component: REMOVEDPage, },
    { title: 'Tin bài', path: '/articles-sent-approve', component: SENTAPPROVINGPage, },
    { title: 'Tin bài', path: '/articles-sent-edit', component: SENTEDITINGPage, },
    { title: 'Tin bài', path: '/articles-wait-approving', component: WAITAPPROVINGPage, },
    { title: 'Tin bài', path: '/articles-wait-editing', component: WAITEDITINGPage, },
    { title: 'Tin bài', path: '/articles-approving', component: APPROVINGPage, },
    {
        title: 'Soạn nội dung bài viết lưu tạm',
        path: '/articles-template/edit/:id',
        component: ArticleEditPage
    },
    {
        title: 'Soạn nội dung bài viết lưu tạm',
        path: '/articles-published/edit/:id',
        component: ArticleEditPage
    },
    {
        title: 'Danh mục',
        path: '/products-category',
        component: ProductCategoryPage
    },
    { title: 'SP', path: '/products-template', component: ProductDraftPage, },
    { title: 'SP', path: '/products-published', component: ProductPublishedPage, },
    { title: 'SP', path: '/products-deleted', component: ProductDeletedPage, },
    { title: 'SP', path: '/products-removed', component: ProductRemovedPage, },
    
    { title: 'SP', path: '/products-template/edit/:id', component: ProductEditPage, },
    { title: 'SP', path: '/products-published/edit/:id', component: ProductEditPage, },
    { title: 'SP', path: '/products-deleted/edit/:id', component: ProductEditPage, },
    { title: 'SP', path: '/products-removed/edit/:id', component: ProductEditPage, },
] as AppComponent[]
export default AppRoutes.map(route => {
    return {
        ...route,
        Component: route.component as React.FC
    };
});