import { axiosClient } from "../../utils/axiosClient";
import { jwtTokenHelper } from "../../utils/jwtTokenHelper";
import { memoizedRefreshToken } from "../../utils/refreshToken";
import { LoginRequest } from "../models/accounts";
import { BaseResponse } from "../base/BaseResponse";

const baseUrl: string = (process.env.REACT_APP_API_URL?.toString() as string);

export const ApiClient = {
    GET: request('GET'),
    POST: request('POST'),
    PUT: request('PUT'),
    DELETE: request('DELETE'),
    LOGIN: login()
};

function request(method: string) {
    return async (url: string, body?: any | undefined): Promise<BaseResponse> => {
        try {
            await memoizedRefreshToken();
            const config = {
                method,
                url: `${baseUrl}${url}`,
                headers: { Authorization: `Bearer ${jwtTokenHelper.GET()}`,  'Content-Type': 'application/json' },
                data: body ? JSON.stringify(body) : '',
            }
            const reponse = await axiosClient<any, BaseResponse>(config);
            return reponse;
        } catch (error: any) {
            if (error?.response?.status === 401) {
                return {
                    isSuccess: false,
                    message: "Bạn không được phân quyền để thực hiện thao tác này. Vui lòng liên hệ Quản trị viên để được hỗ trợ."
                } as BaseResponse;
            } else {
                return {
                    isSuccess: false,
                    message: error?.response?.data?.message || error?.message || "Có lỗi"
                } as BaseResponse;
            }
        }
    }
}

function login() {
    return async (url: string, body: LoginRequest): Promise<BaseResponse> => {
        try {
            const config = {
                method: 'POST',
                url: `${baseUrl}${url}`,
                headers: { 'Content-Type': 'application/json'},
                data: JSON.stringify(body)
            }
            const reponse = await axiosClient<any, BaseResponse>(config);
            return reponse;
        } catch (error: any) {
            if (error?.response?.status === 401) {
                return {
                    isSuccess: false,
                    message: "Bạn không được phân quyền để thực hiện thao tác này. Vui lòng liên hệ Quản trị viên để được hỗ trợ."
                } as BaseResponse;
            } else {
                return {
                    isSuccess: false,
                    message: error?.response?.data?.message || error?.message || "Có lỗi"
                } as BaseResponse;
            }
        }
    }
}
