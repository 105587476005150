import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { AppState } from "../../../context/stores/rootReducers";
import { useEffect } from "react";
import { actions } from "../../../context/stores/rootActions";
import { eBaseActionStatus } from "../../../context/base/eBaseActionStatus";

export function useLookupProductCategory() {
	const dispatch = useDispatch();
	const { status, allProductCategorys } = useSelector((state: AppState) => state.productCategory);
	useEffect(() => {
		if (status === eBaseActionStatus.idle || status === eBaseActionStatus.reload) dispatch(actions.productCategory.getAllRequest());
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return { product_category_all: allProductCategorys };
}
