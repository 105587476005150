import React, { useEffect, useState } from 'react';
import {
	ButtonCancel,
	ButtonSave,
	BaseForm,
	FormGroup,
	ModalBody,
	ModalDraggable,
	ModalFooter,
	ModalHeader,
	NumberBox,
	SwitchBox,
	TextBox,
} from '../../components/base';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useWatch } from 'react-hook-form';
import { ArticleCategoryModel, dfArticleCategoryModel, validArticleCategorySchema } from '../../context/models/articles-category/ArticleCategoryModel';
import { slugHelper } from '../../utils/slugHelper';
import { ComboBoxArticleCategory } from '../../components/shared/hoc-article-category/ComboBoxArticleCategory';

type IArticleCategoryDetailModalProps = {
	isShow: boolean;
	title: string;
	detailModel?: ArticleCategoryModel;
	isSaving: boolean | false;
	onClose: () => void;
	onSubmit: (data: ArticleCategoryModel) => void;
};

const ArticleCategoryDetail = (props: IArticleCategoryDetailModalProps) => {
	const { isShow, title, detailModel, onSubmit, onClose, isSaving } = props;
	const isAddMode = !detailModel;
	const [initialValues, setInitialValues] = useState<ArticleCategoryModel>(dfArticleCategoryModel);

	const {
		control,
		handleSubmit,
		reset,
		setValue,
		formState: { errors, isSubmitting },
	} = useForm<ArticleCategoryModel>({
		resolver: yupResolver(validArticleCategorySchema()),
		mode: 'all',
		defaultValues: initialValues,
	});

	const onSubmitHandler = (values: ArticleCategoryModel) => {
		onSubmit(values);
	};
    
	const watchChangeName = useWatch({ control, name: 'name', defaultValue: '' });

	useEffect(() => {
		const slug = slugHelper.toSlug(watchChangeName);
		setValue('slug', slug);
		setInitialValues((prev) => ({ ...prev, slug }));
	}, [watchChangeName, setValue]);

	useEffect(() => {
		if (initialValues) {
			reset(initialValues);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValues, reset]);

	useEffect(() => {
		if (detailModel) {
			setInitialValues((prev) => ({ ...prev, ...detailModel }));
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [detailModel]);

	return (
		<>
			<ModalDraggable show={isShow} sizeClass='sm' type='primary' onClose={onClose}>
				<ModalHeader classDragg={true} title={title} onClose={() => onClose()} />
				<ModalBody>
					<BaseForm onSubmit={handleSubmit(onSubmitHandler)}>
						<TextBox
							name={'name'}
							error={errors.name?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, name: value }));
							}}
							value={initialValues.name}
							hasValid={true}
							label='Tên chuyên mục'
						/>
						<TextBox
							name={'slug'}
							error={errors.slug?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, slug: value }));
							}}
							value={initialValues.slug}
							hasValid={true}
							label='Slug'
							helpBlock={`Link truy cập nội dung: <code>ten-chuyen-muc</code>. Hoặc nhập tên vào hệ thống sẽ tự tạo.`}
						/>
						<ComboBoxArticleCategory
							name='parentId'
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, parentId: value }));
							}}
							value={initialValues.parentId}
							error={errors.parentId?.message}
							label='Chuyên mục cha'
						/>
						<FormGroup name='status_box' label='Trạng thái'>
							<SwitchBox
								name='inActive'
								label='Ngừng áp dụng'
								error={errors.inActive?.message}
								onValueChanged={(value) => {
									setInitialValues((prev) => ({ ...prev, inActive: value }));
								}}
								value={initialValues.inActive}
								sizeClass='sm'
							/>
						</FormGroup>
						<FormGroup name='menu_box' label='Hiển thị trên Menu chính'>
							<SwitchBox
								name='isHidden'
								label='Ẩn'
								error={errors.isHidden?.message}
								onValueChanged={(value) => {
									setInitialValues((prev) => ({ ...prev, isHidden: value }));
								}}
								value={initialValues.isHidden}
								sizeClass='sm'
							/>
							{!initialValues.isHidden && (
								<>
									<NumberBox
										name={'sortOrder'}
										error={errors.sortOrder?.message}
										onValueChanged={(value) => {
											setInitialValues((prev) => ({ ...prev, sortOrder: value }));
										}}
										value={initialValues.sortOrder}
										label='Vị trí hiển thị'
									/>
								</>
							)}
						</FormGroup>
                        <FormGroup name='sub_menu_box' label='Hiển thị trên Menu phụ'>
							<SwitchBox
								name='isHiddenSub'
								label='Ẩn'
								error={errors.isHiddenSub?.message}
								onValueChanged={(value) => {
									setInitialValues((prev) => ({ ...prev, isHiddenSub: value }));
								}}
								value={initialValues.isHiddenSub}
								sizeClass='sm'
							/>
							{!initialValues.isHiddenSub && (
								<>
									<NumberBox
										name={'sortOrderSub'}
										error={errors.sortOrderSub?.message}
										onValueChanged={(value) => {
											setInitialValues((prev) => ({ ...prev, sortOrderSub: value }));
										}}
										value={initialValues.sortOrderSub}
										label='Vị trí hiển thị'
									/>
								</>
							)}
						</FormGroup>
                        <FormGroup name='footer_menu_box' label='Hiển thị trên Menu chân trang'>
							<SwitchBox
								name='isHiddenFooter'
								label='Ẩn'
								error={errors.isHiddenFooter?.message}
								onValueChanged={(value) => {
									setInitialValues((prev) => ({ ...prev, isHiddenFooter: value }));
								}}
								value={initialValues.isHiddenFooter}
								sizeClass='sm'
							/>
							{!initialValues.isHiddenFooter && (
								<>
									<NumberBox
										name={'sortOrderFooter'}
										error={errors.sortOrderFooter?.message}
										onValueChanged={(value) => {
											setInitialValues((prev) => ({ ...prev, sortOrderFooter: value }));
										}}
										value={initialValues.sortOrderFooter}
										label='Vị trí hiển thị'
									/>
								</>
							)}
						</FormGroup>
					</BaseForm>
				</ModalBody>
				<ModalFooter>
					<ButtonCancel onClick={onClose} isDisabled={isSaving} />
					<ButtonSave
						type='button'
						isLoading={isSaving}
						isDisabled={isSaving}
						onClick={handleSubmit(onSubmitHandler)}
						text={`${!isAddMode ? 'Cập nhật' : 'Thêm mới'}`}
					/>
				</ModalFooter>
			</ModalDraggable>
		</>
	);
};

export default ArticleCategoryDetail;
