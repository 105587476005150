import { ProductCategoryActionTypes, eProductCategoryActionTypeIds } from "./IProductCategoryActionsTypes"
import { BaseGetActionStatus, eBaseActionStatus } from "../../base/eBaseActionStatus"
import { NotifyHelper } from "../../../utils/NotifyHelper"
import { IProductCategoryState } from "./IProductCategoryState"
import { ProductCategoryModel } from "../../models/products-category/ProductCategoryModel";
import { BasePaginationRespone, dfBasePagination } from "../../base/BasePaginationRespone";
import { dfBaseShowDetail } from "../../base/BaseShowDetail";

const initPaginationResponse: BasePaginationRespone<ProductCategoryModel> = { listDatas: [], pagination: dfBasePagination };
const initialState: IProductCategoryState = {
    status: eBaseActionStatus.idle,
    allProductCategorys: [],
    selectedIds: [],
    showConfirm: false,
    paginationResponse: initPaginationResponse,
    showDetail: dfBaseShowDetail
}
const productCategoryReducer = (state: IProductCategoryState = initialState, action: ProductCategoryActionTypes): IProductCategoryState => {
    switch (action.type) {
        case eProductCategoryActionTypeIds.GET_ALL_REQUEST:
        case eProductCategoryActionTypeIds.GET_PAGINATION_REQUEST:
            return {
                ...state,
                status: BaseGetActionStatus(action)
            }
        case eProductCategoryActionTypeIds.GET_ALL_SUCCESS:
            return {
                ...state,
                allProductCategorys: action.payload,
                status: BaseGetActionStatus(action)
            }
        case eProductCategoryActionTypeIds.GET_PAGINATION_SUCCESS:
            return {
                ...state,
                paginationResponse: action.payload,
                status: BaseGetActionStatus(action)
            }
        case eProductCategoryActionTypeIds.GET_ALL_FAILURE:
            return {
                ...state,
                allProductCategorys: [],
                status: BaseGetActionStatus(action)
            }
        case eProductCategoryActionTypeIds.GET_PAGINATION_FAILURE:
            return {
                ...state,
                paginationResponse: initPaginationResponse,
                status: BaseGetActionStatus(action)
            }
        case eProductCategoryActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                selectedIds: action.payload,
            }
        case eProductCategoryActionTypeIds.SHOW_DETAIL:
            return {
                ...state,
                showDetail: action.payload
            }
        case eProductCategoryActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                showConfirm: action.payload
            }
        case eProductCategoryActionTypeIds.SAVE_REQUEST:
            return {
                ...state,
                status: BaseGetActionStatus(action)
            }
        case eProductCategoryActionTypeIds.DELETE_REQUEST:
            return {
                ...state,
                status: BaseGetActionStatus(action)
            }
        case eProductCategoryActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success(action.payload || 'Thêm chuyên mục thành công.');
            return {
                ...state,
                showDetail: dfBaseShowDetail,
                status: BaseGetActionStatus(action),
            }
        case eProductCategoryActionTypeIds.DELETE_SUCCESS:
            NotifyHelper.Success(action.payload.toString() || 'Xóa chuyên mục thành công');
            return {
                ...state,
                selectedIds: [],
                showConfirm: false,
                status: BaseGetActionStatus(action),
            }
        case eProductCategoryActionTypeIds.SAVE_FAILURE:
            NotifyHelper.Error(action.payload.toString());
            return {
                ...state,
                showDetail: dfBaseShowDetail,
                status: BaseGetActionStatus(action),
            }
        case eProductCategoryActionTypeIds.DELETE_FAILURE:
            NotifyHelper.Error(action.payload.toString());
            return {
                ...state,
                selectedIds: [],
                showConfirm: false,
                status: BaseGetActionStatus(action),
            }
        case eProductCategoryActionTypeIds.NEED_RELOAD:
            return {
                ...state,
                status: BaseGetActionStatus(action)
            }
        default:
            return state;
    }
}
export default productCategoryReducer;