import React from 'react';

interface IPageContainerProps {
	children: any;
}

export const PageContainer = ({ children }: IPageContainerProps) => {
	return (
		<>
			<div className='container-fluid'>{children}</div>
		</>
	);
};
