import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
    BaseForm,
    ButtonCancel,
    ButtonSave,
    DxDateBox,
    FormGroup,
    ModalBody,
    ModalDraggable,
    ModalFooter,
    ModalHeader,
    TextBox,
} from '../../../components/base';
import { ComboBoxArticleCategory } from '../../../components/shared/hoc-article-category/ComboBoxArticleCategory';
import ComboBoxUser from '../../../components/shared/hoc-users/ComboBoxUser';
import { ArticleSelectRequest } from '../../../context/models/articles/ArticleSelectRequest';

type IFilterBoxProps = {
	isShow: boolean;
	filterModel: ArticleSelectRequest;
	isLoading: boolean | false;
	onClose: () => void;
	onSubmit: (data: ArticleSelectRequest) => void;
};

const FilterBox = (props: IFilterBoxProps) => {
	const { isShow, onSubmit, onClose, isLoading, filterModel } = props;
	const [initialValues, setInitialValues] = useState<ArticleSelectRequest>(filterModel);
	const {
		control,
		handleSubmit,
		reset,
		setValue,
		formState: { errors },
	} = useForm<ArticleSelectRequest>({
		mode: 'all',
		defaultValues: initialValues,
	});
	const onSubmitHandler = (values: ArticleSelectRequest) => {
		onSubmit(values);
	};

	useEffect(() => {
		if (initialValues) {
			reset(initialValues);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValues, reset]);

	return (
		<ModalDraggable show={isShow} sizeClass='sm' type='primary' onClose={onClose}>
			<ModalHeader classDragg={true} title={'Tìm kiếm tin bài'} onClose={() => onClose()} />
			<ModalBody>
				<BaseForm onSubmit={handleSubmit(onSubmitHandler)}>
					<TextBox
						name={'searchString'}
						error={errors.searchString?.message}
						onValueChanged={(value) => {
							setInitialValues((prev) => ({ ...prev, searchString: value }));
						}}
						value={initialValues.searchString}
						label='Từ khóa'
					/>
					<ComboBoxArticleCategory
						name='catId'
						onValueChanged={(value) => {
							setInitialValues((prev) => ({ ...prev, catId: value }));
						}}
						value={initialValues.catId}
						error={errors.catId?.message}
						label='Chuyên mục'
					/>
                    <ComboBoxUser
						name='author'
						onValueChanged={(value) => {
							setInitialValues((prev) => ({ ...prev, author: value }));
						}}
                        valueExpr='userName'
						value={initialValues.author}
						error={errors.author?.message}
						label='Tác giả'
					/>
					<FormGroup label='Ngày xuất bản'>
						<DxDateBox
							name={'beginDate'}
							error={errors.beginDate?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, beginDate: value }));
							}}
							value={initialValues.beginDate}
							label='Từ ngày'
						/>
						<DxDateBox
							name={'endDate'}
							error={errors.endDate?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, endDate: value }));
							}}
							value={initialValues.endDate}
							label='Từ ngày'
						/>
					</FormGroup>
				</BaseForm>
			</ModalBody>
			<ModalFooter>
				<ButtonCancel text='Đóng' onClick={onClose} isDisabled={isLoading} />
				<ButtonSave type='button' isLoading={isLoading} isDisabled={isLoading} onClick={handleSubmit(onSubmitHandler)} text={`Tìm kiếm`} />
			</ModalFooter>
		</ModalDraggable>
	);
};

export default FilterBox;
