import { axiosClient } from "./axiosClient";
import jwt_decode from 'jwt-decode';
import mem from "mem";
import { jwtTokenHelper } from "./jwtTokenHelper";
import { BaseResponse } from '../context/base/BaseResponse';
import { RefreshTokenResponse } from "../context/models/accounts/RefreshTokenResponse";

const baseUrl: string = (process.env.REACT_APP_API_URL?.toString() as string);

type jwt_decodeResult = {
    exp: any
}
let refreshTokenRequest: Promise<any> | null;

const checkIsTokenExpired = (): boolean => {
    try {
        const data: jwt_decodeResult = jwt_decode(jwtTokenHelper.GET())
        if (jwtTokenHelper.GET() && data) {
            const exp = data.exp;
            if (parseInt(exp) > Math.floor(Date.now() / 1000)) {
                return false;
            }
        }
        return true;
    } catch (error) {
        return true;
    }
}

const refreshToken = () => {
    return new Promise<{ isSuccess: boolean, tokens: string }>(resolve => {
        setTimeout(async () => {
            try {
                const config = {
                    method: 'POST',
                    url: `${baseUrl}/auth/refresh-token`,
                    headers: { 'Content-Type': 'application/json' },
                    data: JSON.stringify({ jwtToken: jwtTokenHelper.GET(), refreshToken: jwtTokenHelper.GET('refreshToken') }),
                    withCredentials: true,
                }
                const { isSuccess, result } = await axiosClient<any, BaseResponse>(config);
                if (isSuccess && result) {
                    const { jwtToken, refreshToken } = result as RefreshTokenResponse;
                    resolve({
                        isSuccess: true,
                        tokens: `${jwtToken} ${refreshToken}`
                    });
                } else {
                    resolve({
                        isSuccess: false,
                        tokens: ""
                    });
                }
            } catch (error) {
                resolve({
                    isSuccess: false,
                    tokens: ""
                });
            }
        }, 1000);
    });
}
const refreshIfTokenExpired = async () => {
    const isTokenExpired = checkIsTokenExpired();
    if (isTokenExpired) {
        refreshTokenRequest = refreshTokenRequest
            ? refreshTokenRequest
            : refreshToken();

        const newTokens: { isSuccess: boolean, tokens: string } = await refreshTokenRequest;
        refreshTokenRequest = null;
        if (newTokens.isSuccess && newTokens.tokens.length > 0) {
            jwtTokenHelper.SET(newTokens.tokens);
        }
        else {
            jwtTokenHelper.CLEAR();
            window.location.href = "/login";
        }
    }
}

const maxAge = 10000;
export const memoizedRefreshToken = mem(refreshIfTokenExpired, { maxAge, });