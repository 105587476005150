import moment from "moment";
import * as Yup from 'yup';
import { eProductStatus } from "./eProductStatus";

export type ProductModel = {
    id: number;
    sku: string;
    barcode: string;
    name: string;
    alias: string;
    initContent: string;
    summary: string;
    contentHtml: string;
    image: string;
    catId: number;
    supplierId: number;
    price: number;
    priceOnSale: number;
    videoPath: string;
    publishedOnDate: string;
    relationProductIds: string;
    relationArticleIds: string;
    tags: string;
    status: string;
    actionStatus: string;
    metaTitle: string;
    metaDescription: string;
    lockedBy: string;
    lockedOnDate: string;
}

export const dfProductModel: ProductModel = {
    id: 0,
    sku: '',
    barcode: '',
    name: '',
    alias: '',
    initContent: '',
    summary: '',
    contentHtml: '',
    image: '',
    catId: 0,
    supplierId: 0,
    price: 0,
    priceOnSale: 0,
    videoPath: '',
    publishedOnDate: moment().format(),
    relationProductIds: '',
    relationArticleIds: '',
    tags: '',
    status: eProductStatus.DRAFT,
    actionStatus: eProductStatus.DRAFT,
    metaDescription: '',
    metaTitle: '',
    lockedBy: '',
    lockedOnDate: moment().format(),
}

export const validProductSchema = () => Yup.object().shape({
    name: Yup.string().required("Vui lòng nhập tên sản phẩm."),
    alias: Yup.string().required("Vui lòng nhập link sản phẩm."),
    catId: Yup.number().required("Vui lòng chọn nhóm sản phẩm.").min(1,"Vui lòng chọn nhóm sản phẩm"),
    sku: Yup.string().required("Vui lòng nhập SKU sản phẩm."),
    barcode: Yup.string(),
    initContent: Yup.string(),
    sumary: Yup.string(),
    contentHtml: Yup.string(),
    image: Yup.string(),
    supplierId: Yup.number(),
    price: Yup.number().required('Vui lòng nhập giá bán').min(1, "Vui lòng nhập giá bán"),
    priceOnSale: Yup.number(),
    videoPath: Yup.string(),
    publishedOnDate: Yup.string(),
    relationProductIds: Yup.string(),
    relationArticleIds: Yup.string(),
    tags: Yup.string(),
    status: Yup.string(),
    lockedBy: Yup.string(),
    lockedOnDate: Yup.string(),
});