import { CheckBoxList } from '../../../base';
import { useLookupRoleScopes } from '../useLookupRoleScopes';

type ICheckedBoxListRoleScopeProps = {
	name: string;
	selectedValue: any[];
	onSelectionChanged: (values: any[]) => void;
	label?: string;
	labelSmall?: boolean;
	hasValid?: boolean;
	error?: string;
	sizeClass?: 'xs' | 'sm' | 'md' | 'lg';
	helpBlock?: string;
};

export const CheckedBoxListRoleScope = (props: ICheckedBoxListRoleScopeProps) => {
	const { label, labelSmall, hasValid } = props;
	const { role_scopes_lookup } = useLookupRoleScopes();
	return (
		<>
			<div className='form-group'>
				{label && (
					<div className='form-label-group'>
						<label className={`form-label ${labelSmall ? 'form-label-small' : ''}`}>
							{label} {hasValid && <em className='text-danger'>(*)</em>}
						</label>
					</div>
				)}
				<div className={`form-control-wrap`}>
					<CheckBoxList dataSource={role_scopes_lookup} displaySubExpr='description' displayExpr='name' keyExpr='id' {...props} />
				</div>
			</div>
		</>
	);
};