import { NotifyHelper } from "../../utils/NotifyHelper";
import { axiosClient } from "../../utils/axiosClient";
import { jwtTokenHelper } from "../../utils/jwtTokenHelper";
import { memoizedRefreshToken } from "../../utils/refreshToken";
import { BaseResponse } from "../base/BaseResponse";


const baseUrl: string = (process.env.REACT_APP_API_URL?.toString() as string);

export const ApiUpload = {
    upload: upload()
};

function upload() {
    return async (url: string, body?: any | undefined): Promise<BaseResponse> => {
        try {
            await memoizedRefreshToken();
            const config = {
                method: 'POST',
                url: `${baseUrl}${url}`,
                headers: { Authorization: `Bearer ${jwtTokenHelper.GET()}`, 'Content-Type': 'multipart/form-data' },
                data: body,
            }
            const reponse = await axiosClient<any, BaseResponse>(config);
            NotifyHelper.Success("Upload thành công");
            return reponse;
        } catch (error: any) {
            if (error?.response?.status === 401) {
                NotifyHelper.Error("Bạn không được phân quyền để thực hiện thao tác này. Vui lòng liên hệ Quản trị viên để được hỗ trợ.");
                return {
                    isSuccess: false,
                    message: "Bạn không được phân quyền để thực hiện thao tác này. Vui lòng liên hệ Quản trị viên để được hỗ trợ."
                } as BaseResponse;
            } else {
                NotifyHelper.Error(error?.response?.data?.message || error?.message || "Có lỗi");
                return {
                    isSuccess: false,
                    message: error?.response?.data?.message || error?.message || "Có lỗi"
                } as BaseResponse;
            }
        }
    }
}