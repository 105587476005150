export type BasePaginationRespone<T> = {
    listDatas: T[],
    pagination?: BasePagination
}
export type BasePagination = {
    currentPage: number,
    pageSize: number,
    totalRecords: number,
    totalPages: number
}
export const dfBasePagination : BasePagination = {
    currentPage: 1,
    pageSize: 20,
    totalPages: 0,
    totalRecords: 0
} 