import React from 'react';

type ITextBoxProps = {
	name: string;
	value: string;
	onValueChanged: (value: string) => void;
	label?: string;
	error?: string;
	sizeClass?: 'xs' | 'sm' | 'md' | 'lg';
	helpBlock?: string;
	placeholder?: string;
	type?: string;
	labelSmall?: boolean;
	isDisabled?: boolean;
	hasValid?: boolean;
	className?: string;
	classWrapper?: string;
};

export const TextBox = ({
	value,
	error,
	name,
	label,
	type,
	sizeClass,
	isDisabled,
	helpBlock,
	labelSmall,
	hasValid,
	placeholder,
	className,
	classWrapper,
	onValueChanged,
}: ITextBoxProps) => {
	const showError = error ? true : false;
	return (
		<>
			<div className='form-group'>
				{label && (
					<div className='form-label-group'>
						<label className={`form-label ${labelSmall ? 'form-label-small' : ''}`} htmlFor={name}>
							{label} {hasValid && <em className='text-danger'>(*)</em>}
						</label>
					</div>
				)}
				<div className={`form-control-wrap ${classWrapper}`}>
					<input
						id={name}
						type={type || 'text'}
						name={name}
						value={value}
						disabled={isDisabled}
						placeholder={placeholder}
						onChange={(e) => onValueChanged(e.target.value)}
						className={`form-control form-control-${sizeClass || 'xs'} ${showError ? 'error' : ''} ${className}`}
					/>
					{showError && <span className='form-note invalid'>{error}</span>}
					{helpBlock && <span className='form-note text-muted' dangerouslySetInnerHTML={{ __html: helpBlock }} />}
				</div>
			</div>
		</>
	);
};
