import { DxSelectBox } from '../../../base/DxSelectBox';
import { useLookupProductCategory } from '../useLookupProductCategory';

type IComboBoxProductCategoryProps = {
	name: string;
	value: any;
	valueExpr?: string;
	displayExpr?: string;
	onValueChanged: (value: any) => void;
	label?: string;
	error?: string;
	sizeClass?: 'xs' | 'sm' | 'md' | 'lg';
	helpBlock?: string;
	labelSmall?: boolean;
	isDisabled?: boolean;
	hasValid?: boolean;
	placeholder?: string;
};

const ComboBoxProductCategory = (props: IComboBoxProductCategoryProps) => {
	const { product_category_all } = useLookupProductCategory();

	return (
		<DxSelectBox
			dataSource={product_category_all}
			valueExpr={'id'}
			displayExpr={'name'}
			{...props}
			placeholder={props.placeholder || 'Chọn danh mục'}
			isClearable={true}
		/>
	);
};
export default ComboBoxProductCategory;