export type ArticleSelectRequest = {
    searchString: string;
    catId: number;
    author: string;
    status: string;
    beginDate: string;
    endDate: string;
    langCode: string;
}

export const df_ArticleSelectRequest : ArticleSelectRequest = {
    author:'',
    beginDate: '1900-01-01',
    catId: 0,
    endDate: '1900-01-01',
    langCode: '',
    searchString: '',
    status: ''
}