export type BaseShowDetail<T> = {
    detailData?: T,
    isShow: boolean
}

export type BaseShowDetailId<T> = {
    id?: T,
    isShow: boolean
}

export const dfBaseShowDetail: BaseShowDetail<any> = {
    isShow: false
}

export const dfBaseShowDetailId: BaseShowDetailId<any> = {
    isShow: false
}