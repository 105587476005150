import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../context/stores/rootActions';
import { AppState } from '../../../context/stores/rootReducers';
import { eBaseActionStatus } from '../../../context/base/eBaseActionStatus';


export function useLookupUserGroup() {
    const dispatch = useDispatch();
    const { status, users_group_all } = useSelector((state: AppState) => state.usersGroup);
    useEffect(() => {
        if (status === eBaseActionStatus.idle || status === eBaseActionStatus.reload) dispatch(actions.usersGroup.getAllRequest());
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return { users_group_lookup: users_group_all };
}
