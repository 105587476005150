import { Action } from 'redux';

export enum eBaseActionStatus {
	idle = 'idle',
	loading = 'loading',
	saving = 'saving',
	complete = 'complete',
	reload = 'reload',
}

const getStatusMatches = (actionType: string) => /(.*)_(REQUEST|SAVING|SUCCESS|FAILURE|RELOAD)/.exec(actionType);

export const BaseGetActionStatus = (action: Action, status?: eBaseActionStatus) => {
	const matches = getStatusMatches(action.type);
	if (!matches) {
		return status ? status : eBaseActionStatus.idle;
	}
	const [, , requestStatus] = matches;
	if (status) return status;
	if (requestStatus === 'REQUEST') return status ? status : eBaseActionStatus.loading;
	if (requestStatus === 'SAVING') return status ? status : eBaseActionStatus.saving;
	if (requestStatus === 'SUCCESS') return status ? status : eBaseActionStatus.complete;
	if (requestStatus === 'FAILURE') return status ? status : eBaseActionStatus.complete;
	if (requestStatus === 'RELOAD') return status ? status : eBaseActionStatus.reload;
	return eBaseActionStatus.idle;
};
