import { useMemo } from 'react';
import { RoleScopeModel } from '../../../../context/models/roles/RoleScopeModel';
import { Badges, IBadgesData } from '../../../base/Badges';

type IBadgesRoleScopeProps = {
	value: number[];
	role_scopes_all: RoleScopeModel[];
};

export const BadgesRoleScope = (props: IBadgesRoleScopeProps) => {
	const { value, role_scopes_all } = props;

	const dataSource: IBadgesData[] = useMemo(() => {
		let datas = role_scopes_all ? role_scopes_all?.filter((x) => value?.includes(x.id)) : [];
		return datas.map((data) => ({ text: data.name, bgClass: data.id === 0 ? 'danger' : undefined }));
	}, [role_scopes_all, value]);

	return <Badges dataSource={dataSource} preset='dimoutline' bgClass='primary' />;
};