import React, { useMemo } from 'react';
import { useAuth } from '../../../utils/AuthProvider';
import { Button, ButtonSave } from '../../../components/base';
import { ProductSaveRule, eProductStatus } from '../../../context/models/products/eProductStatus';

type IProductHeaderButtonProps = {
	productStatus: string;
	isOwner?: boolean;
	isSaving: boolean;
	isActionDisable: boolean;
	handleSubmit: () => void;
	onActionConfirm: (actionChange: eProductStatus) => void;
};

export const ProductButton = ({
	productStatus,
	isOwner,
	isSaving,
	isActionDisable,
	handleSubmit,
	onActionConfirm,
}: IProductHeaderButtonProps) => {
	const { account } = useAuth();
	const actionsRule = useMemo(() => {
		return ProductSaveRule({ status: productStatus, isOwner: isOwner || false });
	}, [productStatus, isOwner]);

	if (!account || account == null) return null;
	return (
		<>
			<li>
				<ButtonSave type='button' theme='secondary' preset='dimoutline'  isLoading={isSaving} isDisabled={isSaving} onClick={() => handleSubmit()} text={`Lưu thay đổi`} />
			</li>
			{actionsRule.includes(eProductStatus.PUBLISHED) && isActionDisable && (
				<>
					<li>
						<Button
							theme='primary'
							preset='dimoutline'
							onClick={() => onActionConfirm(eProductStatus.PUBLISHED)}
							text='Đăng bán ngay'
							icon='icon ni ni-check-thick'
						/>
					</li>
				</>
			)}
		</>
	);
};
