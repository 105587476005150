import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import { useLocation, useOutlet } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { MenuResponse } from '../../context/models/accounts/MenuResponse';
import { useAuth } from '../../utils/AuthProvider';
import { NotAccess } from '../../components/container';

const acceptLink = (path: string, menus: MenuResponse[]) => {
	const checkMenu = menus.find((x) => path.includes(x.path));
	return checkMenu ? true : false;
};

const AdminLayout = () => {
	const { menus } = useAuth();
	const outlet = useOutlet();
	const location = useLocation();
	const [showSidebar, setShowSidebar] = useState(false);
	const [showSidebarMobile, setShowSidebarMobile] = useState(false);

	const isAccess = acceptLink(location.pathname, menus);

	useEffect(() => {
		setShowSidebarMobile(false);
	}, [location]);

	return (
		<div className={`nk-body bg-lighter npc-default has-sidebar`}>
			<div className='nk-app-root'>
				<div className='nk-main'>
					<Sidebar
						isShowSideBar={showSidebar}
						isShowSideBarMobile={showSidebarMobile}
						showSideBar={() => {
							setShowSidebar((prev) => !prev);
						}}
						setShowSidebarMobile={() => {
							setShowSidebarMobile((prev) => !prev);
						}}
					/>
					<div className={`nk-wrap`}>
						<div className='nk-header nk-header-fixed is-light'>
							<div className='container-fluid'>
								<div className='nk-header-wrap'>
									<Header showSideBar={() => setShowSidebarMobile((prev) => !prev)} />
								</div>
							</div>
						</div>
						<div className='nk-content p-0'>
							<div className='nk-content-inner'>
								<div className='nk-content-body'>
									{/* <div className='nk-content-overflow'>
										<SimpleBar forceVisible='x' autoHide={false} className='nk-content-view'> */}
									{isAccess ? outlet : <NotAccess />}
									{/* </SimpleBar>
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default AdminLayout;
