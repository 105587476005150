import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
    BaseForm,
    ButtonCancel,
    ButtonSave,
    ModalBody,
    ModalDraggable,
    ModalFooter,
    ModalHeader,
    TextBox
} from '../../../components/base';
import ComboBoxProductCategory from '../../../components/shared/hoc-product-category/ComboBoxProductCategory';
import { ProductSelectRequest } from '../../../context/models/products/ProductSelectRequest';

type IFilterBoxProps = {
	isShow: boolean;
	filterModel: ProductSelectRequest;
	isLoading: boolean | false;
	onClose: () => void;
	onSubmit: (data: ProductSelectRequest) => void;
};

const FilterBox = (props: IFilterBoxProps) => {
	const { isShow, onSubmit, onClose, isLoading, filterModel } = props;
	const [initialValues, setInitialValues] = useState<ProductSelectRequest>(filterModel);
	const {
		control,
		handleSubmit,
		reset,
		setValue,
		formState: { errors },
	} = useForm<ProductSelectRequest>({
		mode: 'all',
		defaultValues: initialValues,
	});
	const onSubmitHandler = (values: ProductSelectRequest) => {
		onSubmit(values);
	};

	useEffect(() => {
		if (initialValues) {
			reset(initialValues);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValues, reset]);

	return (
		<ModalDraggable show={isShow} sizeClass='sm' type='primary' onClose={onClose}>
			<ModalHeader classDragg={true} title={'Tìm kiếm sản phẩm'} onClose={() => onClose()} />
			<ModalBody>
				<BaseForm onSubmit={handleSubmit(onSubmitHandler)}>
					<TextBox
						name={'searchString'}
						error={errors.searchString?.message}
						onValueChanged={(value) => {
							setInitialValues((prev) => ({ ...prev, searchString: value }));
						}}
						value={initialValues.searchString}
						label='Từ khóa'
					/>
					<ComboBoxProductCategory
						name='catId'
						onValueChanged={(value) => {
							setInitialValues((prev) => ({ ...prev, catId: value }));
						}}
						value={initialValues.catId}
						error={errors.catId?.message}
						label='Chuyên mục'
					/>
    
				</BaseForm>
			</ModalBody>
			<ModalFooter>
				<ButtonCancel text='Đóng' onClick={onClose} isDisabled={isLoading} />
				<ButtonSave type='button' isLoading={isLoading} isDisabled={isLoading} onClick={handleSubmit(onSubmitHandler)} text={`Tìm kiếm`} />
			</ModalFooter>
		</ModalDraggable>
	);
};

export default FilterBox;
