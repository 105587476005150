import { BasePaginationRespone } from "../../base/BasePaginationRespone";
import { BaseSelectRequest } from "../../base/BaseSelectRequest";
import { BaseShowDetail } from "../../base/BaseShowDetail";
import { IActionTypeBase } from "../../base/IBaseActionTypes";
import { UserGroupModel } from "../../models/users-group/UserGroupModel";

export enum eUserGroupActionTypeIds {
    GET_ALL_REQUEST = "UserGroup_GET_ALL_REQUEST",
    GET_ALL_SUCCESS = "UserGroup_GET_ALL_SUCCESS",
    GET_ALL_FAILURE = "UserGroup_GET_ALL_FAILURE",

    GET_PAGINATION_REQUEST = "UserGroup_GET_PAGINATION_REQUEST",
    GET_PAGINATION_SUCCESS = "UserGroup_GET_PAGINATION_SUCCESS",
    GET_PAGINATION_FAILURE = "UserGroup_GET_PAGINATION_FAILURE",

    SAVE_SAVING = "UserGroup_SAVE_SAVING",
    SAVE_SUCCESS = "UserGroup_SAVE_SUCCESS",
    SAVE_FAILURE = "UserGroup_SAVE_FAILURE",

    DELETE_SAVING = "UserGroup_DELETE_SAVING",
    DELETE_SUCCESS = "UserGroup_DELETE_SUCCESS",
    DELETE_FAILURE = "UserGroup_DELETE_FAILURE",

    NEED_RELOAD = "UserGroup_NEED_RELOAD",

    CHANGE_SELECTED_IDS = "UserGroup_CHANGE_SELECTED_IDS",
    SHOW_DETAIL = "UserGroup_SHOW_DETAIL",
    CLOSE_DETAIL = "UserGroup_CLOSE_DETAIL",
    SHOW_CONFIRM = "UserGroup_SHOW_CONFIRM",
    CLOSE_CONFIRM = "UserGroup_CLOSE_CONFIRM",
}

export interface UserGroupAction_GETALL_Request extends IActionTypeBase<eUserGroupActionTypeIds.GET_ALL_REQUEST, undefined> { }
export interface UserGroupAction_GETALL_Success extends IActionTypeBase<eUserGroupActionTypeIds.GET_ALL_SUCCESS, UserGroupModel[]> { }
export interface UserGroupAction_GETALL_Failure extends IActionTypeBase<eUserGroupActionTypeIds.GET_ALL_FAILURE, Error | string> { }

export interface UserGroupAction_GETPAGINATION_Request extends IActionTypeBase<eUserGroupActionTypeIds.GET_PAGINATION_REQUEST, BaseSelectRequest> { }
export interface UserGroupAction_GETPAGINATION_Success extends IActionTypeBase<eUserGroupActionTypeIds.GET_PAGINATION_SUCCESS, BasePaginationRespone<UserGroupModel>> { }
export interface UserGroupAction_GETPAGINATION_Failure extends IActionTypeBase<eUserGroupActionTypeIds.GET_PAGINATION_FAILURE, Error | string> { }

export interface UserGroupAction_SAVE_Request extends IActionTypeBase<eUserGroupActionTypeIds.SAVE_SAVING, UserGroupModel> { }
export interface UserGroupAction_SAVE_Success extends IActionTypeBase<eUserGroupActionTypeIds.SAVE_SUCCESS, string> { }
export interface UserGroupAction_SAVE_Failure extends IActionTypeBase<eUserGroupActionTypeIds.SAVE_FAILURE, Error | string> { }

export interface UserGroupAction_DELETE_Request extends IActionTypeBase<eUserGroupActionTypeIds.DELETE_SAVING, number[]> { }
export interface UserGroupAction_DELETE_Success extends IActionTypeBase<eUserGroupActionTypeIds.DELETE_SUCCESS, string> { }
export interface UserGroupAction_DELETE_Failure extends IActionTypeBase<eUserGroupActionTypeIds.DELETE_FAILURE, Error | string> { }

export interface UserGroupAction_RELOAD extends IActionTypeBase<eUserGroupActionTypeIds.NEED_RELOAD, undefined> { }

export interface UserGroupAction_SHOW_DETAIL extends IActionTypeBase<eUserGroupActionTypeIds.SHOW_DETAIL, BaseShowDetail<UserGroupModel>> { }
export interface UserGroupAction_CHANGE_SELECTED_IDS extends IActionTypeBase<eUserGroupActionTypeIds.CHANGE_SELECTED_IDS, number[]> { }
export interface UserGroupAction_SHOW_CONFIRM extends IActionTypeBase<eUserGroupActionTypeIds.SHOW_CONFIRM, boolean> { }

export type UserGroupActionTypes = UserGroupAction_GETALL_Request | UserGroupAction_GETALL_Success | UserGroupAction_GETALL_Failure |
    UserGroupAction_GETPAGINATION_Request | UserGroupAction_GETPAGINATION_Success | UserGroupAction_GETPAGINATION_Failure |
    UserGroupAction_SAVE_Request | UserGroupAction_SAVE_Success | UserGroupAction_SAVE_Failure |
    UserGroupAction_DELETE_Request | UserGroupAction_DELETE_Success | UserGroupAction_DELETE_Failure | UserGroupAction_RELOAD |
    UserGroupAction_SHOW_DETAIL | UserGroupAction_CHANGE_SELECTED_IDS | UserGroupAction_SHOW_CONFIRM 