import { BasePagination } from '../../base/BasePaginationRespone'
import { BaseAction } from '../../base/IBaseActionRespone'
import { RoleScopeModel } from '../../models/roles/RoleScopeModel'
import { RoleScopesAction_GETALL_Failure, RoleScopesAction_GETALL_Request, RoleScopesAction_GETALL_Success, eRoleScopesActionTypeIds } from './IRoleScopesActionsTypes'

export const roleScopesActions = {
    //ALL
    getAllRequest: (): RoleScopesAction_GETALL_Request => BaseAction(eRoleScopesActionTypeIds.GET_ALL_REQUEST, undefined),
    getAllSuccess: (data: RoleScopeModel[], pagination?: BasePagination): RoleScopesAction_GETALL_Success => BaseAction(eRoleScopesActionTypeIds.GET_ALL_SUCCESS, { data, pagination }),
    getAllFailure: (error: Error | string): RoleScopesAction_GETALL_Failure => BaseAction(eRoleScopesActionTypeIds.GET_ALL_FAILURE, error),
}