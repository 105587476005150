import { FormatContentHtmlRequest } from "../base/FormatContentHtmlRequest";
import { ApiClient } from "../base/_ApiClient";
import { ApiUpload } from "../base/_ApiUpload";

export const uploadApi = {
    UploadImagesAsync: ({ saveName, files }: { files: File[] | FileList, saveName?: string }) => {
        let formData = new FormData();
        if (files.length > 0) {
            Array.from(files).map((file) => {
                return formData.append("file", file);
            })
        }
        // formData.append("save_file_name", saveName || '');
        return ApiUpload.upload('/z-upload/images', formData)
    },
    UploadFilesAsync: ({ saveName, files }: { files: File[] | FileList, saveName?: string }) => {
        let formData = new FormData();
        if (files.length > 0) {
            Array.from(files).map((file) => {
                return formData.append("file", file);
            })
        }
        // formData.append("save_file_name", saveName || '');
        return ApiUpload.upload('/z-upload/files', formData)
    },
    DownloadImageInContentHtmlAsync: (request: FormatContentHtmlRequest) => {
        return ApiClient.POST('/z-upload/download-image-in-content', request)
    },
    CleanAllStyleContentAsync: (request: FormatContentHtmlRequest) => {
        return ApiClient.POST('/z-upload/clean-content-html', request)
    }
};