import * as Yup from 'yup';

export type UserModel = {
    id: number;
    userName: string;
    firstName: string;
    lastName: string;
    midleName: string;
    fullName: string;
    avatar: string;
    email: string;
    phoneNumber: string;
    isActive: boolean;
    roles: number[],
    password: string,
    passwordConfirmed: string,
    userGroupId: number;
}

export const df_UserModel: UserModel = {
    id: 0,
    email: '',
    userName: '',
    fullName: '',
    isActive: true,
    avatar: '',
    phoneNumber: '',
    firstName: '',
    lastName: '',
    midleName: '',
    password: '',
    passwordConfirmed: '',
    roles: [],
    userGroupId: 0
}

export const valid_UserModel = (isAddMode: boolean) => Yup.object().shape({
    email: Yup.string().required('Vui lòng nhập email.').email('Định dạng email không phù hợp.'),
    userName: Yup.string().required('Vui lòng nhập Tài khoản.'),
    fullName: Yup.string().required('Vui lòng nhập Tên đầy đủ.'),
    roles: Yup.array(Yup.number()),
    password: Yup.string()
        .transform((x) => (x === '' ? undefined : x))
        .concat(isAddMode ? Yup.string().required('Vui lòng nhập Mật khẩu.') : Yup.string())
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            'Mật khẩu tối thiểu 8 kí tự. Gồm CHỮ HOA, chữ thường, số và kí tự đặc biệt (!@#$...).'
        )
        .min(6, 'Mật khẩu tối thiểu 6 ký tự'),
    passwordConfirmed: Yup.string()
        .transform((x) => (x === '' ? undefined : x))
        .when('password', (password, schema: any) => {
            if (password && isAddMode) return schema.required('Vui lòng nhập Mật khẩu xác nhận.');
        })
        .oneOf([Yup.ref('password')], 'Mật khẩu xác nhận không khớp.'),
    isactive: Yup.bool(),
});