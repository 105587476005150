import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Button, ButtonCancel } from '../../base';
import Draggable from 'react-draggable';
const modalRootEl = document.getElementById('modal-root') as HTMLElement;

type ConfirmModalProps = {
	show: boolean;
	innerText: string | 'Bạn chắc chắn thực hiện hành động này?';
	btnConfirmText?: string;
	btnCloseText?: string;
	onConfirmed(): void;
	onClose(): void;
	type: 'danger' | 'primary' | 'success';
	sizeClass?: 'sm' | 'md' | 'lg';
	isLoading?: boolean | false;
	isDisable?: boolean | false;
};

export const ConfirmModal = (props: ConfirmModalProps) => {
	const { show, innerText, onConfirmed, onClose, type, btnCloseText, btnConfirmText, isLoading, sizeClass, isDisable } = props;

	const closeOnEscapeKeyDown = (e: any) => {
		if ((e.charCode || e.keyCode) === 27) {
			props.onClose();
		}
	};

	useEffect(() => {
		document.body.addEventListener('keydown', closeOnEscapeKeyDown);
		return function cleanup() {
			document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
		};
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return ReactDOM.createPortal(
		<>
			{show && (
				<>
					<Draggable handle='.modal-dialog'>
						<div className={`modal fade md-form zoom show`} tabIndex={-1}>
							<div className={`modal-dialog dragg modal-${sizeClass || 'sm'}`} role='document'>
								<div className='modal-content'>
									<div className='modal-body text-center'>
										<div className='nk-modal'>
											<h4 className='nk-modal-title'>
												<em className={`mx-1 nk-modal-icon icon icon-circle icon-circle-lg ni ni-question bg-${type || 'primary'}`}></em>
												Thông báo!
											</h4>
											<div className='nk-modal-text'>
												<div className='caption-text' dangerouslySetInnerHTML={{ __html: innerText }}></div>
											</div>
										</div>
									</div>
									<div className='modal-footer bg-lighter'>
										<ButtonCancel
											icon='icon ni ni-cross-circle'
											text={btnCloseText || 'Đóng'}
											isDisabled={isLoading}
											onClick={() => onClose()}
										/>
										<Button
											text={btnConfirmText || 'Xác nhận'}
											onClick={() => onConfirmed()}
											theme={type || 'primary'}
											className='btn-sm'
											icon='icon ni ni-done'
											iconMode='right'
											isDisabled={isLoading || isDisable}
											isLoading={isLoading}
										/>
									</div>
								</div>
							</div>
						</div>
					</Draggable>
					<div className={`modal-backdrop fade show`}></div>
				</>
			)}
		</>,
		modalRootEl
	);
};
