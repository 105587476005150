import React, { useCallback, useEffect, useState } from 'react';
import { ButtonCancel, ButtonSave, ModalBody, ModalDraggable, ModalFooter, ModalHeader } from '../../../../components/base';
import { CheckedBoxListRole } from '../../../../components/shared/system';
import { MenuItemResponse } from '../../../../context/models/menus/MenuItemResponse';
import { MenuUpdateRoleRequest } from '../../../../context/models/menus/MenuUpdateRoleRequest';
import { createUUID } from '../../../../utils/createUUID';

type IMenuPhanQuyenModalProps = {
	isShow: boolean;
	title: string;
	menuModel: MenuItemResponse;
	isSaving: boolean | false;
	onClose: () => void;
	onSubmit: (data: MenuUpdateRoleRequest) => void;
};

const MenuPhanQuyenModal = React.memo((props: IMenuPhanQuyenModalProps) => {
	const { menuModel, isShow, title, onSubmit, onClose, isSaving } = props;

	const [roleSelected, setRoleSelected] = useState<number[]>([]);

	const handleSubmit = useCallback(() => {
		onSubmit({ menu_id: menuModel.id, role_ids: roleSelected });
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [roleSelected, menuModel]);

	useEffect(() => {
		if (menuModel.roleIds) setRoleSelected(menuModel.roleIds);
		else onClose();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [menuModel.roleIds]);

	return (
		<>
			<ModalDraggable show={isShow} sizeClass='md' type='primary' onClose={onClose}>
				<ModalHeader classDragg={true} title={title} onClose={() => onClose()} />
				<ModalBody>
					<CheckedBoxListRole
						name={createUUID()}
						label='Quyền hạn'
						hasValid={true}
						onSelectionChanged={(value: any[]) => {
							setRoleSelected(value || []);
						}}
						selectedValue={roleSelected}
					/>
				</ModalBody>
				<ModalFooter>
					<ButtonCancel onClick={onClose} isDisabled={isSaving} />
					<ButtonSave type='button' isLoading={isSaving} isDisabled={isSaving} onClick={() => handleSubmit()} text={`Phân quyền`} />
				</ModalFooter>
			</ModalDraggable>
		</>
	);
});

export default MenuPhanQuyenModal;
