import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ProductActionConfirmRequest } from '../../../context/models/products/ProductActionConfirmRequest';
import { ProductModel } from '../../../context/models/products/ProductModel';
import { ProductSelectRequest } from '../../../context/models/products/ProductSelectRequest';
import { eProductStatus, getProductStatusName } from '../../../context/models/products/eProductStatus';
import { BasePaginationRespone } from '../../../context/base/BasePaginationRespone';
import { BaseShowDetailId } from '../../../context/base/BaseShowDetail';
import { ProductActionTypes } from '../../../context/stores/products/IProductActionsTypes';
import { eBaseActionStatus } from '../../../context/base/eBaseActionStatus';
import { actions } from '../../../context/stores/rootActions';
import { AppState } from '../../../context/stores/rootReducers';
import { jwtTokenHelper } from '../../../utils/jwtTokenHelper';
import ProductTable from '../product-table';

type IProductDeletedPageProps = {
	titlePage: string;
	productStatus: eProductStatus;
	langCode: string;
	isOwner: boolean;
	status: eBaseActionStatus;
	paginationResponse: BasePaginationRespone<ProductModel>;
	selectedIds: number[];
	showDetail: BaseShowDetailId<number>;
	showConfirm: ProductActionConfirmRequest;
	handleReloadData: (request: ProductSelectRequest) => void;
	handleGetDetail: (id: number) => void;
	handleSaveData: (data: ProductModel) => void;
	handleDeleteData: (data: number[]) => void;
	onChangeSelectedIds: (ids: number[]) => void;
	handleChangeStatus: (data: ProductActionConfirmRequest) => void;
	onShowDetail: (data: BaseShowDetailId<number>) => void;
	onShowConfirm: (data: ProductActionConfirmRequest) => void;
};

const ProductDeletedPage = (props: IProductDeletedPageProps) => {
	return <ProductTable {...props}  />
};

const mapStateToProps = (state: AppState) => {
	return {
		titlePage: `Sản phẩm ${getProductStatusName(eProductStatus.DELETED)}`,
		productStatus: eProductStatus.DELETED,
		isOwner: false,
		langCode: jwtTokenHelper.LANGCODE(),
		status: state.product.status,
		paginationResponse: state.product.paginationResponse,
		selectedIds: state.product.selectedIds,
		showDetail: state.product.showDetail,
		showConfirm: state.product.showConfirm,
	};
};

const mapDispatchToProps = (dispatch: Dispatch<ProductActionTypes>) => ({
	handleReloadData: (request: ProductSelectRequest) => {
		dispatch(actions.product.getByOwnerActionRequest(request));
	},
	handleGetDetail: (id: number) => {
		dispatch(actions.product.detailRequest(id));
	},
	handleSaveData: (data: ProductModel) => {
		dispatch(actions.product.saveRequest(data));
	},
	handleDeleteData: (ids: number[]) => {
		dispatch(actions.product.deleteRequest(ids));
	},
	handleChangeStatus: (detail: ProductActionConfirmRequest) => {
		dispatch(
			actions.product.changeStatusRequest({
				actionStatus: detail.actionStatus,
				productIds: detail.ids || [],
				receivedBy: detail.receivedBy || '',
				message: detail.message || '',
				subject: detail.subject || '',
			})
		);
	},
	onChangeSelectedIds: (ids: number[]) => {
		dispatch(actions.product.changeSelectedIds(ids));
	},
	onShowDetail: (data: BaseShowDetailId<number>) => {
		dispatch(actions.product.showDetail(data));
	},
	onShowConfirm: (detail: ProductActionConfirmRequest) => {
		dispatch(actions.product.showConfirm(detail));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDeletedPage);
