import { BasePaginationRespone } from "../../base/BasePaginationRespone";
import { BaseSelectRequest } from "../../base/BaseSelectRequest";
import { BaseShowDetailId } from "../../base/BaseShowDetail";
import { BaseAction } from "../../base/IBaseActionRespone";
import { ArticleChangeStatusRequest } from "../../models/articles/ArticleChangeStatusRequest";
import { ArticleItemResponse, ArticleModel } from "../../models/articles/ArticleModel";
import { ArticleSelectRequest } from "../../models/articles/ArticleSelectRequest";
import { ArticleAction_CHANGE_STATUS_Failure, ArticleAction_CHANGE_STATUS_Request, ArticleAction_CHANGE_STATUS_Success, ArticleAction_CHANGE_SELECTED_IDS, ArticleAction_DELETE_Failure, ArticleAction_DELETE_Request, ArticleAction_DELETE_Success, ArticleAction_GETBY_ACTION_Failure, ArticleAction_GETBY_ACTION_Request, ArticleAction_GETBY_ACTION_Success, ArticleAction_GETBY_OWNER_ACTION_Failure, ArticleAction_GETBY_OWNER_ACTION_Request, ArticleAction_GETBY_OWNER_ACTION_Success, ArticleAction_GETPAGINATION_Failure, ArticleAction_GETPAGINATION_Request, ArticleAction_GETPAGINATION_Success, ArticleAction_GETPUBLISHED_Failure, ArticleAction_GETPUBLISHED_Request, ArticleAction_GETPUBLISHED_Success, ArticleAction_GET_DETAIL_Failure, ArticleAction_GET_DETAIL_Request, ArticleAction_GET_DETAIL_Success, ArticleAction_RELOAD, ArticleAction_SAVE_Failure, ArticleAction_SAVE_Request, ArticleAction_SAVE_Success, ArticleAction_SHOW_CONFIRM, ArticleAction_SHOW_DETAIL, eArticleActionTypeIds } from "./IArticleActionsTypes";
import { ArticleActionConfirmRequest } from "../../models/articles/ArticleActionConfirmRequest";

export const articleActions = {
    //LIST
    getPaginationRequest: (request: BaseSelectRequest): ArticleAction_GETPAGINATION_Request => BaseAction(eArticleActionTypeIds.GET_PAGINATION_REQUEST, request),
    getPaginationSuccess: (data: BasePaginationRespone<ArticleItemResponse>): ArticleAction_GETPAGINATION_Success => BaseAction(eArticleActionTypeIds.GET_PAGINATION_SUCCESS, data),
    getPaginationFailure: (error: Error | string): ArticleAction_GETPAGINATION_Failure => BaseAction(eArticleActionTypeIds.GET_PAGINATION_FAILURE, error),
    //ACTION
    getByActionRequest: (request: ArticleSelectRequest): ArticleAction_GETBY_ACTION_Request => BaseAction(eArticleActionTypeIds.GET_BY_ACTION_REQUEST, request),
    getByActionSuccess: (data: BasePaginationRespone<ArticleItemResponse>): ArticleAction_GETBY_ACTION_Success => BaseAction(eArticleActionTypeIds.GET_BY_ACTION_SUCCESS, data),
    getByActionFailure: (error: Error | string): ArticleAction_GETBY_ACTION_Failure => BaseAction(eArticleActionTypeIds.GET_BY_ACTION_FAILURE, error),
    //OWNER ACTION
    getByOwnerActionRequest: (request: ArticleSelectRequest): ArticleAction_GETBY_OWNER_ACTION_Request => BaseAction(eArticleActionTypeIds.GET_BY_OWNER_ACTION_REQUEST, request),
    getByOwnerActionSuccess: (data: BasePaginationRespone<ArticleItemResponse>): ArticleAction_GETBY_OWNER_ACTION_Success => BaseAction(eArticleActionTypeIds.GET_BY_OWNER_ACTION_SUCCESS, data),
    getByOwnerActionFailure: (error: Error | string): ArticleAction_GETBY_OWNER_ACTION_Failure => BaseAction(eArticleActionTypeIds.GET_BY_OWNER_ACTION_FAILURE, error),
    //PUBLISHED
    getPublishedRequest: (request: ArticleSelectRequest): ArticleAction_GETPUBLISHED_Request => BaseAction(eArticleActionTypeIds.GET_PUBLISHED_REQUEST, request),
    getPublishedSuccess: (data: BasePaginationRespone<ArticleItemResponse>): ArticleAction_GETPUBLISHED_Success => BaseAction(eArticleActionTypeIds.GET_PUBLISHED_SUCCESS, data),
    getPublishedFailure: (error: Error | string): ArticleAction_GETPUBLISHED_Failure => BaseAction(eArticleActionTypeIds.GET_PUBLISHED_FAILURE, error),
    //GET_DETAIL
    detailRequest: (id: any): ArticleAction_GET_DETAIL_Request => BaseAction(eArticleActionTypeIds.GET_DETAIL_REQUEST, id),
    detailSuccess: (detail?: ArticleModel): ArticleAction_GET_DETAIL_Success => BaseAction(eArticleActionTypeIds.GET_DETAIL_SUCCESS, detail),
    detailFailure: (error: Error | string): ArticleAction_GET_DETAIL_Failure => BaseAction(eArticleActionTypeIds.GET_DETAIL_FAILURE, error),
    //SAVE
    saveRequest: (entity: ArticleModel): ArticleAction_SAVE_Request => BaseAction(eArticleActionTypeIds.SAVE_REQUEST, entity),
    saveSuccess: (message: string): ArticleAction_SAVE_Success => BaseAction(eArticleActionTypeIds.SAVE_SUCCESS, message),
    saveFailure: (error: Error | string): ArticleAction_SAVE_Failure => BaseAction(eArticleActionTypeIds.SAVE_FAILURE, error),
    //DELETE
    deleteRequest: (ids: any[]): ArticleAction_DELETE_Request => BaseAction(eArticleActionTypeIds.DELETE_REQUEST, ids),
    deleteSuccess: (message: string): ArticleAction_DELETE_Success => BaseAction(eArticleActionTypeIds.DELETE_SUCCESS, message),
    deleteFailure: (error: Error | string): ArticleAction_DELETE_Failure => BaseAction(eArticleActionTypeIds.DELETE_FAILURE, error),
    //CHANGE_STATUS
    changeStatusRequest: (changeAction: ArticleChangeStatusRequest): ArticleAction_CHANGE_STATUS_Request => BaseAction(eArticleActionTypeIds.CHANGE_STATUS_REQUEST, changeAction),
    changeStatusSuccess: (message: string): ArticleAction_CHANGE_STATUS_Success => BaseAction(eArticleActionTypeIds.CHANGE_STATUS_SUCCESS, message),
    changeStatusFailure: (error: Error | string): ArticleAction_CHANGE_STATUS_Failure => BaseAction(eArticleActionTypeIds.CHANGE_STATUS_FAILURE, error),
    //RELOAD
    needReload: (): ArticleAction_RELOAD => BaseAction(eArticleActionTypeIds.NEED_RELOAD, undefined),
    //ACTIONs
    changeSelectedIds: (ids: string[]): ArticleAction_CHANGE_SELECTED_IDS => BaseAction(eArticleActionTypeIds.CHANGE_SELECTED_IDS, ids),
    showDetail: (detail: BaseShowDetailId<string>): ArticleAction_SHOW_DETAIL => BaseAction(eArticleActionTypeIds.SHOW_DETAIL, detail),
    showConfirm: (detail: ArticleActionConfirmRequest): ArticleAction_SHOW_CONFIRM => BaseAction(eArticleActionTypeIds.SHOW_CONFIRM, detail)
}