import React, { useMemo } from 'react';
import { useAuth } from '../../../utils/AuthProvider';
import { Button, ButtonSave } from '../../../components/base';
import { ArticleSaveRule, eArticleStatus } from '../../../context/models/articles/eArticleStatus';

type IArticleHeaderButtonProps = {
	articleStatus: string;
	isOwner?: boolean;
	isSaving: boolean;
	isActionDisable: boolean;
	handleSubmit: () => void;
	onActionConfirm: (actionChange: eArticleStatus) => void;
};

export const ArticleButton = ({
	articleStatus,
	isOwner,
	isSaving,
	isActionDisable,
	handleSubmit,
	onActionConfirm,
}: IArticleHeaderButtonProps) => {
	const { account } = useAuth();
	const actionsRule = useMemo(() => {
		return ArticleSaveRule({ status: articleStatus, isOwner: isOwner || false });
	}, [articleStatus, isOwner]);

	if (!account || account == null) return null;
	return (
		<>
			<li>
				<ButtonSave type='button' theme='secondary' preset='dimoutline'  isLoading={isSaving} isDisabled={isSaving} onClick={() => handleSubmit()} text={`Lưu thay đổi`} />
			</li>

			{actionsRule.includes(eArticleStatus.BACK) && isActionDisable && (
				<>
					<li>
						<Button
							theme='warning'
							preset='dimoutline'
							onClick={() => onActionConfirm(eArticleStatus.BACK)}
							text='Trả bài'
							icon='icon ni ni-reply-fill'
						/>
					</li>
				</>
			)}
			{actionsRule.includes(eArticleStatus.PUBLISHED) && isActionDisable && (
				<>
					<li>
						<Button
							theme='primary'
							preset='dimoutline'
							onClick={() => onActionConfirm(eArticleStatus.PUBLISHED)}
							text='Xuất bản ngay'
							icon='icon ni ni-check-thick'
						/>
					</li>
				</>
			)}
		</>
	);
};
