import React, { HTMLProps, useEffect, useMemo } from 'react';
import { Column, ColumnDef, flexRender, getCoreRowModel, getFilteredRowModel, RowData, Table, useReactTable } from '@tanstack/react-table';
import { BasePagination } from '../../../context/base/BasePaginationRespone';
import { PaginationRender } from '../../../utils/PaginationHelper';

declare module '@tanstack/table-core' {
	interface ColumnMeta<TData extends RowData, TValue> {
		width?: number;
		columnClass?: string;
		headerClass?: string;
		displayMode?: 'tb-col-xxl' | 'tb-col-lg' | 'tb-col-md' | 'tb-col-mb' | 'tb-col-xs';
	}
}

type ITableProps<TData> = {
	data: TData[];
	columns: ColumnDef<TData>[];
	refresh: () => void;
	keyFn: string;
};

export const TableDefault = (props: ITableProps<any>) => {
	const { keyFn, columns, data, refresh } = props;

	const initialColumns = React.useMemo<ColumnDef<any>[]>(() => {
		let result: ColumnDef<any>[] = [
			{
				id: 'tbl_idx',
				header: () => 'STT',
				cell: ({ row }) => <span>{row.index + 1}</span>,
				meta: { headerClass: 'text-center tb-col-md', columnClass: 'text-center tb-col-md', width: 80 },
			},
		];

		result = [...result, ...columns];

		return result;
	}, [columns]);

	const table = useReactTable<any>({
		data,
		columns: initialColumns,
		enableRowSelection: true, //enable row selection for all rows
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		// getPaginationRowModel: getPaginationRowModel(),
	});
 
	return (
		<>
			<div className='dataTables_wrapper dt-bootstrap4 no-footer'>
				<div className='row justify-between g-2 with-export'>
					<div className='col-12 col-sm-12 text-end'>
						<div className='datatable-filter'>
							<div className='d-flex justify-content-end g-2'>
								<div className='d-flex align-center'>
									<div className='dt-export-title d-none d-md-inline-block'>Làm mới</div>
									<div className='dt-buttons btn-group flex-wrap'>
										<button className='btn btn-secondary buttons-html5' type='button' onClick={() => refresh()}>
											<em className='icon ni ni-reload'></em>
											<span>Làm mới</span>
										</button>{' '}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='datatable-wrap my-3'>
					<table className='datatable-init dataTable nk-tb-list nk-tb-ulist'>
						<thead>
							{table.getHeaderGroups().map((headerGroup) => {
								return (
									<tr key={headerGroup.id} className={`nk-tb-item nk-tb-head nk-tb-headgroup`}>
										{headerGroup.headers.map((header) => {
											const config = header.column.columnDef.meta;
											const width = config ? `${config?.width}px` : undefined;
											return (
												<th
													key={header.id}
													colSpan={header.colSpan}
													style={{ width: width || 'unset' }}
													className={`nk-tb-col ${config?.headerClass ?? ''} ${config?.displayMode ?? ''}`}
												>
													{header.isPlaceholder ? null : (
														<>
															{flexRender(header.column.columnDef.header, header.getContext())}
														</>
													)}
												</th>
											);
										})}
									</tr>
								);
							})}
						</thead>
						<tbody>
							{table.getRowModel().rows.map((row) => {
								return (
									<tr key={row.id} className={`nk-tb-item ${row.index % 2 === 0 ? 'even' : 'odd'}`}>
										{row.getVisibleCells().map((cell) => {
											const config = cell.column.columnDef.meta;
											const width = config ? `${config?.width}px` : undefined;
											return (
												<td
													key={cell.id}
													style={{ width: width || 'unset' }}
													className={`nk-tb-col ${config?.columnClass ?? ''} ${config?.displayMode ?? ''}`}
												>
													{flexRender(cell.column.columnDef.cell, cell.getContext())}
												</td>
											);
										})}
									</tr>
								);
							})}
						</tbody>
						<tfoot>
							<tr className='nk-tb-item nk-tb-foot'>
								<td className='nk-tb-col' colSpan={table.getAllLeafColumns().length - 1}>
									Dữ liệu trang hiện tại (<b>{table.getRowModel().rows.length}</b>)
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
			</div>
		</>
	);
};
