import { combineReducers } from "redux";
import usersReducer from "./users/usersReducer";
import accountReducer from "./auth/accountReducer";
import roleScopesReducer from "./roles/roleScopesReducer";
import rolesReducer from "./roles/rolesReducer";
import articleCategoryReducer from "./articles-category/articleCategoryReducer";
import articleReducer from "./articles/articleReducer";
import productReducer from "./products/productReducer";
import productCategoryReducer from "./products-categroy/productCategoryReducer";
import menusReducer from "./menus/menusReducer";
import usersGroupReducer from "./users-group/userGroupReducer";

const rootReducers = combineReducers({
    users: usersReducer,
    usersGroup: usersGroupReducer,
    menus: menusReducer,
    account: accountReducer,
    roles: rolesReducer,
    roleScopes: roleScopesReducer,
    articleCategory: articleCategoryReducer,
    article: articleReducer,
    product: productReducer,
    productCategory: productCategoryReducer
});

export type AppState = ReturnType<typeof rootReducers>;

export default rootReducers;